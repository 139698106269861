import AuthStore from "./auth/modules/store/AuthStore";
import GroupUserStore from "../../pages/Main/templates/Users/modules/store/GroupUserStore";
import HomeworkStore from "../../pages/Main/templates/Homeworks/HomeworkList/modules/store/HomeworkStore";
import ProblemStore from "./problem/store/ProblemStore";
import QuestionStore from "../../pages/Main/templates/Questions/modules/store/QuestionStore";
import CommentStore from "./comment/store/CommentStore";
import ReplyStore from "./reply/store/ReplyStore";
import GraphDataStore from "../../pages/Main/templates/Dashboard/DashGraph/modules/store/GraphDataStore";
import TableDataStore from "../../pages/Main/templates/Dashboard/UserTable/modules/store/TableDataStore";
import BizGroupStore from "./bizGroup/store/BizGroupStore";
import BizClassStore from "./bizClass/store/BizClassStore";
import CuratedProblemStore from "../../pages/Main/templates/Homeworks/Homework/modules/store/CuratedProblemStore";
import SubmissionStore from "../../pages/Main/templates/Submission/modules/store/SubmissionStore";

export default class RootStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.groupUserStore = new GroupUserStore(this);
        this.homeworkStore = new HomeworkStore(this);
        this.problemStore = new ProblemStore(this);
        this.questionStore = new QuestionStore(this);
        this.commentStore = new CommentStore(this);
        this.bizGroupStore = new BizGroupStore(this);
        this.bizClassStore = new BizClassStore(this);
        this.replyStore = new ReplyStore(this);
        this.graphDataStore = new GraphDataStore(this);
        this.tableDataStore = new TableDataStore(this);
        this.missionStore = new CuratedProblemStore(this);
        this.submissionStore = new SubmissionStore(this);
    }
}
