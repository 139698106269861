import {flow, makeAutoObservable} from 'mobx';
import AuthRepository from "../repository/AuthRepository"
import {UserModel} from "../model/UserModel";
import {
    getToken,
    removeTokenFromCookie,
    setRefreshToken,
    setToken,
} from "../../../../../utils/cookies";

export default class AuthStore {
    loginUser = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            login: flow,
            verifyToken: flow,
            activate: flow,
        }, {autoBind: true});
        this.verifyToken();
    }

    unsetToken = () => {
        removeTokenFromCookie({ path: "/"});
    };

    *login(body) {
        try {
            const response = yield AuthRepository.login(body);

            setToken(response.data.access);
            setRefreshToken(response.data.refresh)

            window.location.reload();
        } catch(error) {
            alert(error.response.data);
        }
    };

    *verifyToken() {
        const token = getToken();
        if(token) {
            const response = yield AuthRepository.verifyToken();
            this.loginUser = new UserModel(response.data);
        }
        else {
            this.loginUser = {};
        }
    }

    *activate({uidb64, token}) {
        yield AuthRepository.activate({uidb64, token});
    }

    logout = expired => {
        const token = getToken();
        this.unsetToken();
        if (!token || expired) {
            return window.setTimeout(function() {
                window.location.reload();
            }, 5000);
        }
        window.location.href = '/';
    }

    isUserLoggedIn = () => {
        const token = getToken();
        return !!token;
    }

    get isTest() {
        return this.isUserLoggedIn() && this.loginUser && this.loginUser.username === "card";
    }

    isUserPaid = (key) => {
        if(!this.isUserLoggedIn() || !this.loginUser)
            return false;

        return key ? this.loginUser.paid[key] === true : Object.keys(this.loginUser.paid).length !== 0;
    }

    get currUser() {
        return this.loginUser;
    }
}
