import React from 'react';
import MarkdownPreview from "@uiw/react-markdown-preview";
import RemarkMathPlugin from 'remark-math';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';

const _mapProps = (props) => ({
    ...props,
    escapeHtml: false,
    plugins: [
        RemarkMathPlugin
    ],
    renderers: {
        ...props.renderers,
        math: ({ value }) => <TeX block>{value}</TeX>,
        inlineMath: ({ value }) => <TeX>{value}</TeX>
    }
});

const Markdown = (props) => <MarkdownPreview {..._mapProps(props)} />;

export default Markdown;
