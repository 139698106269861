import {flow, makeAutoObservable} from "mobx";
import SubmissionWebSocket from "../websocket/SubmissionWebSocket";
import {WebSocketType} from "../../../../../../utils/webSocket";
import SubmissionRepository from "../repository/SubmissionRepository";
import {SubmissionModel, SubmissionStatusType} from "../model/SubmissionModel";

export default class SubmissionStore {
    submissions = [];
    count = 0;
    isFetching = false;
    page = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            getFailedTestcaseUrl: flow,
        }, {autoBind: true});
    }

    setIsFetching(state) {
        this.isFetching = state;
    }

    setPage(state) {
        this.page = state;
    }

    subscribeSubmission({submissionId}) {
        const body = {
            type: WebSocketType.SubscribeSubmission,
            data: {
                id: submissionId,
            }
        }

        SubmissionWebSocket.add({body});
    }

    _onMessage(e) {
        const response = JSON.parse(e.data);
        const type = response.type;
        const data = response.data;

        if(type === WebSocketType.SubscribeSuccess) {
            return;
        } else if(type === WebSocketType.SubscribeFailed) {

        } else if(type === WebSocketType.UpdataSubmission) {
            if('process' in data.result) {
                this.submissions.replace(this.submissions.map(submission => {
                    if(Number(data.id) === submission.id) {
                        return new SubmissionModel({
                            ...submission,
                            result: {
                                ...submission.result,
                                result: SubmissionStatusType.InProgress,
                                process: data.result.process
                            }
                        })
                    }

                    return submission;
                }));

                return
            }

            const newSubmission = new SubmissionModel(data);
            this.submissions.replace(this.submissions.map(submission => {
                if(newSubmission.id === submission.id)
                    return newSubmission;

                return submission;
            }));
        } else if(type === WebSocketType.PublishSubmission) {
            if(this.page)
                return;

            const newSubmission = new SubmissionModel(data);
            this.submissions.unshift(newSubmission);
        }
    }

    open() {
        SubmissionWebSocket.open({onMessage: this._onMessage})
    }

    close() {
        SubmissionWebSocket.close();
    }

    _replace_submissions({data}){
        this.submissions.replace(data.map(submission => new SubmissionModel(submission)));
        data.map(submission => {
            this.subscribeSubmission({submissionId: submission.id});
        });
    }

    *fetchAll({bizGroupId, bizClassId, pageSize, page}) {
        const response = yield SubmissionRepository.findAll({bizGroupId, bizClassId, pageSize, page});
        this.count = response.data.count;
        this._replace_submissions({data: response.data.results});
    }

    *getFailedTestcaseUrl({problemId, submissionId, resultId, isInputFile}) {
        const response = yield SubmissionRepository.getFailedTestcaseUrl({problemId, submissionId, resultId, isInputFile});
        return response.data.url;
    }
}
