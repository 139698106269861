import React, {useEffect, useState} from "react";
import {useObserver} from "mobx-react-lite";
import {UsersTemplate} from "./UsersTemplate";
import {useStores} from "../../../../../common/hooks/UseStores";
import {snackActions} from "../../../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../../../utils/errorHandler";
import {updateAllKeys} from "../../../../../utils/immutableLodash";
import Box from "@material-ui/core/Box";
import {BulkUpload} from "./BulkUpload";
import {makeStyles} from "@material-ui/core";
import {GroupUserDAOModel} from "../modules/model/GroupUserModel";
import {LimitCountIndicator} from "./LimitCountIndicator";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: 'calc(100vw - 64px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        width: 1480,
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-between'
    }
}))

export const UsersContainer = ({bizClassId, bizGroupId}) => {
    const {groupUserStore} = useStores();
    const [loading, setLoading] = useState({
        left: false,
        right: false
    });
    const [setting, setSetting] = useState({
        left: {},
        right: {}
    })
    const [failed, setFailed] = useState("");
    const [failedOpen, setFailedOpen] = useState(false);

    const classes = useStyles();

    useEffect(()=> {
        groupUserStore.fetchAll({bizGroupId, bizClassId, page: 0});
        groupUserStore.fetchAllGroupUsers({bizGroupId, bizClassId, page: 0});
    }, [bizGroupId, bizClassId])

    const handleUpdate = (body) => {
        setLoading(updateAllKeys(loading, true));
        groupUserStore
            .updateGroupUsers({bizGroupId, bizClassId, body: {user_ids: body}, setting: setting.left})
            .then(() => {
                setLoading(updateAllKeys(loading, false));
                snackActions.success('성공적으로 수정되었습니다.');
            })
            .catch(error => {
                errorToast(error);
                setLoading(updateAllKeys(loading, false));
            });
    }

    const handleBulkUpdate = (body) => {
        setLoading(updateAllKeys(loading, true));

        const newUsers = body.map(row => new GroupUserDAOModel(row));

        groupUserStore
            .updateBulkGroupUsers({bizGroupId, bizClassId, body: {users: newUsers}, setting: setting.left})
            .then((data) => {
                setLoading(updateAllKeys(loading, false));
                snackActions.success('성공적으로 추가되었습니다.');
                if(data) {
                    setFailed(data);
                    setFailedOpen(true);
                }
            })
            .catch(error => {
                errorToast(error);
                setLoading(updateAllKeys(loading, false));
            });
    }

    const handleFailedClose = () => {
        setFailed("");
        setFailedOpen(false);
    }

    const handlePageChange = (id, page,) => {
        setLoading({...loading, [id]: true});

        if(id === 'left')
            groupUserStore.fetchAll({bizGroupId, bizClassId, page, setting: setting.left}).then(()=>setLoading({...loading, [id]: false}));
        else
            groupUserStore.fetchAllGroupUsers({bizGroupId, bizClassId, page, setting: setting.right}).then(()=>setLoading({...loading, [id]: false}));
    }

    const handleSearch = (id, search) => {
        setSetting({
            ...setting,
            [id] : {
                ...setting[id],
                search
            }
        })
        if(id === 'left')
            groupUserStore.fetchAll({bizGroupId, bizClassId, page: 0, setting: {...setting[id], search}}).then(()=>setLoading({...loading, [id]: false}));
        else
            groupUserStore.fetchAllGroupUsers({bizGroupId, bizClassId, page: 0, setting: {...setting[id], search}}).then(()=>setLoading({...loading, [id]: false}));
    }

    return useObserver(() => {
        const {groupUsers, users, userCount, groupUserCount} = groupUserStore;

        return (
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <BulkUpload handleBulkUpdate={handleBulkUpdate}
                                failed={failed}
                                handleFailedClose={handleFailedClose}
                                failedOpen={failedOpen} />
                    <LimitCountIndicator />
                </Box>
                <UsersTemplate
                    users={[...users]}
                    groupUsers={[...groupUsers]}
                    userCount={userCount}
                    groupUserCount={groupUserCount}
                    handlePageChange={handlePageChange}
                    loading={loading}
                    handleUpdate={handleUpdate}
                    handleSearch={handleSearch}
                />
            </Box>
        )
    })
}
