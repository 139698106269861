import {flow, makeAutoObservable} from 'mobx';
import ProblemRepository from "../repository/ProblemRepository";
import {ProblemModel} from "../model/ProblemModel";


export default class ProblemStore {
    problems = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            findProblems: flow,
            getProblemsIntro: flow,
        }, {autoBind: true});
    }

    *fetchAll({problemSetting}) {
        const response = yield ProblemRepository.findAll({problemSetting});
        this.problems.replace(response.data.map(problem => new ProblemModel(problem)));
    }

    *findProblems({body}) {
        const response = yield ProblemRepository.findProblems({body});
        return response.data.map(problem => new ProblemModel(problem));
    }

    *getProblemsIntro({problemId}) {
        const response = yield ProblemRepository.getProblemsIntro({problemId})
        return response.data
    }
}
