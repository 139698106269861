import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {ButtonGroup, makeStyles} from "@material-ui/core";
import {useObserver} from "mobx-react-lite";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    dataGrid: {
        width: 922,
        '& .MuiDataGrid-columnHeaderWrapper' : {
            backgroundColor: 'pink',
            position: "sticky",
            overflow: "visible",
            top: "0",
            zIndex: "1100",
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid ##f0f0f0'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid #f0f0f0`,
        },
        '& .MuiDataGrid-row': {
            backgroundColor: '#ffffff',
        },
        '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'pink'
        },
        '& .MuiDataGrid-main': {
            overflow: "visible"
        },
        '& .success': {
            backgroundColor: '#0CC41765',
            color: '#1a3e72',
        },
        '& .fail': {
            backgroundColor: '#e85c4870',
            color: '#1a3e72',
        },
    },
    selectedButton: {
        backgroundColor: '#0D8DBA65',
        width: 20,
        '&:hover': {
            backgroundColor: '#0F88B288',
        },
    },
    button: {
        width: 20
    },
}))

const PaginationTemplate = ({maxPage, page, handlePageChange}) => {
    const classes = useStyles();

    const pageArray = []
    const minIndex = Math.max(1, Math.min(Number(page) - 5, maxPage - 10))
    const maxIndex = Math.min(maxPage, Math.max(Number(page) + 5, 11))
    for(let i = minIndex; i <= maxIndex; i += 1) {
        pageArray.push(i)
    }

    const toButton = number => (
        <Button
            className={Number(page) === number ? classes.selectedButton : classes.button}
            onClick = {()=>handlePageChange(number)}
        >{number}</Button>
    )

    const headerButtons = (
        <Button
            className={classes.button}
            onClick = {()=>handlePageChange(1)}
        >{"<<"}</Button>
    )

    const mainButtons = pageArray.map(pageNum => toButton(pageNum))

    const footerButtons = (
        <Button
            className={classes.button}
            onClick = {()=>handlePageChange(Number(maxPage))}
        >{">>"}</Button>
    )

    return(
        <ButtonGroup>
            {headerButtons}
            {mainButtons}
            {footerButtons}
        </ButtonGroup>
    );
}

export const UserTableTemplate = ({tableDatas, handlePageChange, loading, count, handleSortModelChange, sortModel, usersSolveInfo, page}) => {
    const classes = useStyles();

    const getColumns = () => {
        const column = [
            {field: 'username', headerName: 'Username', width: 200, filterable: false,},
        ]
        if(tableDatas && tableDatas.length && tableDatas[0].solveInfo) {
            Object.keys(tableDatas[0].solveInfo).forEach((key) => {
                if(key !== 'username' && key !== 'id')column.push({field: key, headerName: key, width: 120, filterable: false,})
            })
        }
        return column
    }

    const columns = React.useMemo(getColumns, [tableDatas])

    return useObserver(() => (
        <>
            <PaginationTemplate maxPage={Math.floor((count + 5) / 6)} page={page} handlePageChange={handlePageChange}/>
            <DataGrid
                columns={columns}
                rows={usersSolveInfo}
                className={classes.dataGrid}
                autoHeight
                loading={loading}
                getCellClassName={(params) => {
                    if (params.field === 'username' || params.value === '.') {
                        return '';
                    }
                    return params.value === 'O' ? 'success' : 'fail';
                }}
            />
        </>
    ));
}
