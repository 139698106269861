import {GET, PATCH, POST} from "../../../../utils/api";
import {getArrayQueryString, makeQueryString} from "../../../../utils/makeQueryString";


class CommentRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({questionId}) {
        return GET(`/discussions/${questionId}/comments/`);
    }

    addComment({questionId, body}) {
        return POST(`/discussions/${questionId}/comments/`, body);
    }

    updateComment({questionId, commentId, body}) {
        return PATCH(`/discussions/${questionId}/comments/${commentId}/`, body);
    }
}

// 싱글톤으로 리턴
export default new CommentRepository();
