import Cookies from "universal-cookie";
import {getItem, removeItem, setItem} from "./localStorage";

const cookies = new Cookies();

const CODETREE_TOKEN_NAME = "CODETREE_TOKEN";
const CT_REFRESH_TOKEN_KEY = "CODETREE_REFRESH_TOKEN";

export const getToken = () => {
    return getCookie(CODETREE_TOKEN_NAME);
};

export const removeToken = () => {
    return removeCookie(CODETREE_TOKEN_NAME);
}

export const setTokenToCookie = (value, config) => {
    setCookie(CODETREE_TOKEN_NAME, value, config);
};

export const setToken = (value) => {
    setTokenToCookie(value, {path: "/", expires: new Date(2038, 1, 1)});
};

export const removeTokenFromCookie = (config) => {
    removeCookie(CODETREE_TOKEN_NAME, config);
};

export const setCookie = (name, value, config) => {
    cookies.set(name, value, config);
};

export const getCookie = (name) => {
    return cookies.get(name);
};

export const removeCookie = (name, config) => {
    cookies.remove(name, config);
};

export const getRefreshToken = () => {
    return getCookie(CT_REFRESH_TOKEN_KEY);
};

export const removeRefreshToken = () => {
    return removeCookie(CT_REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (value) => {
    return setCookie(CT_REFRESH_TOKEN_KEY, value, { expires: new Date(2038, 1, 1)})
};
