import {flow, makeAutoObservable} from 'mobx';
import QuestionRepository from "../repository/QuestionRepository";
import {QuestionModel} from "../model/QuestionModel";

export default class QuestionStore {
    questions = [];
    question = undefined;
    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetch: flow,
            updateQuestion: flow,
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId, bizClassId, page, homeworkId, setting}) {
        const response = yield QuestionRepository.findAll({bizGroupId, bizClassId, page, homeworkId, setting});
        this.questions.replace(response.data.results.map(question => new QuestionModel(question)));
        this.count = response.data.count;
    }

    *fetch({questionId}) {
        const response = yield QuestionRepository.find({questionId});
        this.question = new QuestionModel(response.data);
    }

    *updateQuestion({questionId, body}) {
        const response = yield QuestionRepository.updateQuestion({questionId, body});
        const newQuestion = new QuestionModel(response.data);
        this.questions.replace(this.questions.map(question => question.id === Number(questionId) ?
            newQuestion : question));
        this.question = newQuestion;
    }
}
