import {GET} from "../../../../utils/api";

class BizGroupRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll() {
        return GET(`/biz-groups/`);
    }

    find({bizGroupId}) {
        return GET(`/biz-groups/${bizGroupId}`)
    }
}

// 싱글톤으로 리턴
export default new BizGroupRepository();
