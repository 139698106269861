import {GET, PATCH} from "../../../../../../utils/api";
import {makeQueryString} from "../../../../../../utils/makeQueryString";

class GroupUserRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, page, setting}) {
        const queryString = makeQueryString(setting);
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/users/?page=${page+1}${queryString}`);
    }

    findAllGroupUsers({bizGroupId, bizClassId, page, setting}) {
        const queryString = makeQueryString(setting);
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/group-users/?page=${page+1}${queryString}`);
    }

    updateGroupUsers({bizGroupId, bizClassId, body}) {
        return PATCH(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/group-users/`, body);
    }

    updateBulkGroupUsers({bizGroupId, bizClassId, body}) {
        return PATCH(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/group-users-bulk/`, body);
    }

}

// 싱글톤으로 리턴
export default new GroupUserRepository();
