export const LevelType = {
    Easy: 0,
    Medium: 1,
    Hard: 2,
};

export const LevelToLabel = {
    Easy: "쉬움",
    Medium: "보통",
    Hard: "어려움",
};
