import {makeAutoObservable, set} from "mobx";
import {pickCls} from "../../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../../../utils/camelize";


const commentConvertMap = {

};

export class TableDataModel {
    id = 0;
    username = '';
    passedNum = 0;
    totalNum = 0;
    solveInfo = {};

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const tableData = pickCls(this, renameKeys(camelizeKeys(data), commentConvertMap));
        tableData.solveInfo['username'] = (tableData.solveInfo['username'] + ' ( ' + tableData.passedNum.toString()
            + ' / ' + tableData.totalNum.toString() + ' )')
        set(this, tableData);
    }
}
