import {pickBy} from "lodash";
import {keys} from "mobx";

export const pickCls = (obj, data) => {
    const fieldNames = keys(obj);
    const clsData = pickBy(data, (value, key) => fieldNames.includes(key) && (typeof value) !== 'function');
    return clsData;
};

export const updateAllKeys = (obj, val) => {
    return (Object
        .keys(obj)
        .reduce((acc, curr)=>({...acc, [curr]: val}), {}))
}

export const updateOrder = (problems) => {
    return problems.map((problem, i) => ({...problem, order: i+1}));
}
