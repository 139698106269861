import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React, {useState} from "react";
import {SimpleTable} from "../../../../../common/components/SimpleTable";
import {FormControlLabel, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {MissionSelectForm} from "./MissionSelectForm";
import {ProblemForm} from "./ProblemForm";
import {useStores} from "../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    listItem: {
        borderBottom: '1px solid black',
        width: 'fit-content'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '20px 0'
    },
    button: {
        margin: '0 10px'
    },
    buttonCancel: {
        margin: '0 10px',
        backgroundColor: 'pink',
        '&:hover': {
            backgroundColor: '#ffb2c6'
        }
    },
    switch: {
        marginRight: '5px',
    },
}))

const ProblemsList = ({problems}) => {
    const classes = useStyles();

    return (
        <List className={classes.list}>
            {problems.map(problem => (
                <ListItem key={`${problem.name}`} className={classes.listItem}>
                    {`${problem.isIntro ? '기본문제 - ' : ''}${problem.order}. ${problem.name} - ${problem.level} - ${problem.score}`}
                </ListItem>
            ))}
        </List>
    )
}

export const HomeworkView = ({homework, handleEdit, handleDeleteOpen, oldVersion, checkedProblems, handleToggleVersion}) => {
    const classes = useStyles();

    const { missionStore } = useStores();
    const [checkedProblemsSet, setCheckedProblemsSet] = useState(new Set(checkedProblems))

    const filterItems = (item) => {
        if (item === 'id' || item === 'problems')
            return false;
        return true;
    }

    const overrideItems = (curr) => {
        return ({
            key: curr,
            value: homework[curr]
        })
    }

    const items = Object.keys(homework).filter(filterItems).map(overrideItems);
    const items_lower = [{key:'problems', value: <ProblemsList problems={homework['problems']} />}]

    return useObserver( () => {
        const {selectedMission} = missionStore;

        return (
            <Paper className={classes.root}>
                <Box>
                    <SimpleTable items={items} />
                </Box>
                <FormControlLabel
                    className={classes.switch}
                    control={<Switch color="primary" checked={!oldVersion} onChange={handleToggleVersion} />}
                    label="문제집에서 미션 고르기"
                    labelPlacement="start"
                />
                {!oldVersion ?
                    <Box>
                        <MissionSelectForm homework={homework}/>
                        <ProblemForm missionId={selectedMission.id} homeworkProblems={homework.problems} isDisabled={true} checkedProblems={checkedProblemsSet} setCheckedProblems={setCheckedProblemsSet}/>
                    </Box>
                    : <SimpleTable items={items_lower} />
                }
                <Box className={classes.buttons}>
                    <Button onClick={handleEdit} variant='contained' className={classes.button}>
                        EDIT
                    </Button>
                    <Button variant='contained' onClick={handleDeleteOpen} className={classes.buttonCancel}>
                        DELETE
                    </Button>
                </Box>
            </Paper>
    )});
}
