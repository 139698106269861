import {makeAutoObservable, set} from "mobx";
import {GroupUserModel} from "../../../../Users/modules/model/GroupUserModel";
import {pickCls} from "../../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../../../utils/camelize";


const commentConvertMap = {

};

export class GraphDataModel {
    homeworkId = 0;
    problemName = "";
    passedUsers = [];
    inProgressUsers = [];
    untriedUsers = [];
    passedNum = 0;
    inProgressNum = 0;
    untriedNum = 0;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const graphData = pickCls(this, renameKeys(camelizeKeys(data), commentConvertMap));
        graphData.passedUsers = graphData.passedUsers && graphData.passedUsers.map(user => new GroupUserModel(user));
        graphData.inProgressUsers = graphData.inProgressUsers && graphData.inProgressUsers.map(user => new GroupUserModel(user));
        graphData.untriedUsers = graphData.untriedUsers && graphData.untriedUsers.map(user => new GroupUserModel(user));
        graphData.passedNum = graphData.passedUsers.length
        graphData.inProgressNum = graphData.inProgressUsers.length
        graphData.untriedNum = graphData.untriedUsers.length
        set(this, graphData);
    }
}
