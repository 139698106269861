import React from "react";
import {HomeworksContainer} from "./HomeworkList/HomeworksContainer";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {HomeworkContainer} from "./Homework/HomeworkContainer";
import {useObserver} from "mobx-react-lite";

export const Homeworks = ({bizGroupId, bizClassId}) => {
    const match = useRouteMatch();

    return useObserver(() => (
        <Switch>
            <Route exact path={`${match.url}/`} render={() =>
                <HomeworksContainer bizGroupId={bizGroupId} bizClassId={bizClassId}/>
            }/>
            <Route path={`${match.url}/:homeworkId`} render={({match}) =>
                <HomeworkContainer bizGroupId={bizGroupId} bizClassId={bizClassId}
                                   homeworkId={match.params.homeworkId}/>
            }/>
        </Switch>
    ));
}
