import {flow, makeAutoObservable} from 'mobx';
import {CommentModel} from "../model/CommentModel";
import CommentRepository from "../repository/CommentRepository";


export default class CommentStore {
    comments = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            addComment: flow,
            updateComment: flow,
        }, {autoBind: true});
    }

    *fetchAll({questionId}) {
        const response = yield CommentRepository.findAll({questionId});
        this.comments.replace(response.data.map(comment => new CommentModel(comment)));
    }

    *addComment({questionId, body}) {
        const response = yield CommentRepository.addComment({questionId, body});
        this.comments.push(new CommentModel(response.data))
    }

    *updateComment({questionId, commentId, body}) {
        const response = yield CommentRepository.updateComment({questionId, commentId, body});
        this.comments.replace(this.comments.map(comment =>
            Number(commentId) === comment.id ? new CommentModel(response.data) : comment))
    }

    addReplyNum(commentId) {
        this.comments.replace(this.comments.map(comment => comment.id === Number(commentId) ? new CommentModel({...comment, replyNum: ++comment.replyNum}): comment))
    }
}
