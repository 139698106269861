import React from "react";
import {LoginContainer} from "./templates/LoginContainer";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root : {
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.heavy,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const LoginPage = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <LoginContainer />
        </Box>
    )
}

export default LoginPage;
