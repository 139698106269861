import React, {useEffect} from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {useStores} from "../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import Divider from "@material-ui/core/Divider";
import {useHistory} from "react-router-dom";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Moment from "react-moment";
import Markdown from "../../../../../common/components/Markdown";
import {CommentContainer} from "./comment/CommentContainer";
import clsx from "clsx";
import {QuestionDAOModel} from "../modules/model/QuestionModel";
import {snackActions} from "../../../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../../../utils/errorHandler";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(10)
    },
    paper: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    leftHeader: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center'
    },
    rightHeader: {
        display: "flex"
    },
    backButton: {
        minWidth: 100,
        fontWeight: 800,
    },
    title: {
        margin: "auto",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        paddingLeft: theme.spacing(2),
        marginLeft: theme.spacing(1),
        fontWeight: 600,
        overflow: "hidden",
        maxHeight: 20
    },
    editIcon: {
        margin: "auto",
        marginRight: theme.spacing(1)
    },
    deleteIcon: {
        margin: "auto"
    },
    content: {
        padding: theme.spacing(3)
    },
    contentMeta: {
        marginBottom: theme.spacing(3),
        display: "flex"
    },
    personIcon: {
        fontSize: "38px",
        transition: "all 0.5s",
        color: "#fff",
        width: "25px",
        height: "25px",
        padding: "5px 0",
        borderRadius: "50%",
        background: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
        boxShadow: "10px 10px 10px rgba(0,0,0,.05)",
        alignSelf: "center",
    },
    author: {
        fontSize: "13px",
        color: "#9e9e9e",
        marginLeft: theme.spacing(1)
    },
    date: {
        marginLeft: theme.spacing()
    },
    markdown: {
        color: "rgba(0, 0, 0, 0.65)"
    },
    button: {
        padding: theme.spacing(0.5, 1),
        height: 'fit-content',
        marginLeft: theme.spacing(2.5)
    },
    resolved: {
        backgroundColor: '#82ca9d',
        color: '#ffffff'
    },
    unresolved: {

    }
}))

const QuestionTemplate = ({bizGroupId, bizClassId, question, toggleResolved}) => {
    const classes = useStyles();
    const history = useHistory();

    const goBack = () => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/questions`)
    }

    const redirectToProblem = () => {
        window.location.href = `http://www.codetree.ai/problems/${question.problemAlias}`;
    }

    return (
        <Box>
            <Box className={classes.header}>
                <Box className={classes.leftHeader}>
                    <Button className={classes.backButton} color="primary" size="large"
                            onClick={goBack}>{`< 뒤로 가기`}</Button>
                    <Typography className={classes.title}>
                        {question.title}
                    </Typography>
                    <Button
                        className={clsx(classes.button,
                            question.resolved ? classes.resolved : classes.unresolved)
                        } variant='contained' onClick={() => toggleResolved(question)}>
                        {question.resolved ? 'Resolved' : 'Unresolved'}
                    </Button>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={redirectToProblem} >
                        해당 문제로 이동하기
                    </Button>
                </Box>
            </Box>
            <Divider/>
            <Box className={classes.content}>
                <Box className={classes.contentMeta}>
                    <PermIdentityIcon className={classes.personIcon}/>
                    <Typography className={classes.author}>
                        {question.username}
                        <Moment format="YYYY년 MM월 DD일 HH:mm:ss" className={classes.date} parse={"YY/MM/DD hh:mm:ss a"}>
                            {question.createdAt}
                        </Moment>
                    </Typography>
                </Box>
                <Box className={classes.markdown}>
                    <Markdown source={question.description}/>
                </Box>
            </Box>
        </Box>
    )
}

export const Question = (props) => {
    const classes = useStyles();

    const {bizGroupId, bizClassId, questionId} = props;
    const {questionStore, commentStore} = useStores();

    useEffect(()=>{
        questionStore.fetch({questionId});
        commentStore.fetchAll({questionId})
    }, [])

    const toggleResolved = (question) => {
        const newQuestion = new QuestionDAOModel({resolved: !(question.resolved)});
        questionStore.updateQuestion({questionId, body: newQuestion}).then(
            () => {
                snackActions.success("정상적으로 수정되었습니다.")
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );
    }

    return useObserver(() => {
        const {question} = questionStore;
        const {comments} = commentStore;

        if(!(question && question.id === Number(questionId)))
            return false;

        return (
            <Box className={classes.root}>
                <Paper className={classes.paper}>
                    <QuestionTemplate bizGroupId={bizGroupId} bizClassId={bizClassId} question={question} toggleResolved={toggleResolved} />
                    <CommentContainer comments={[...comments]} questionId={questionId} />
                </Paper>
            </Box>
        )
    })
}
