import { w3cwebsocket as W3CWebSocket } from "websocket";
import {baseURL, WebSocketType} from "../../../../../../utils/webSocket";
import {getToken} from "../../../../../../utils/cookies";
import SubmissionStore from "../store/SubmissionStore";

class SubmissionWebSocket {
    socket = null;
    roomId = null;
    initProps = {};

    send = (message, callback) => {
        this.waitForConnection(() => {
            this.socket.send(message);
            if (typeof callback !== 'undefined') {
                callback();
            }
        }, 5, 1000);
    };

    waitForConnection = (callback, retryNum, interval) => {
        if(!retryNum || retryNum === 0) return
        if(this.socket.readyState === 1) { // Open
            callback();
        } else {
            if(this.socket.readyState === 3) { // Closed
                this.open(this.initProps);
            }
            let that = this;
            setTimeout(() => {
                that.waitForConnection(callback, retryNum - 1, interval);
            }, interval);
        }
    };

    open = ({onMessage}) => {
        this.initProps = { onMessage };
        this.close();

        const client = new W3CWebSocket(baseURL + `/submissions/`);
        client.onmessage = onMessage;

        client.onopen = () => {
            client.send(JSON.stringify({
                type: WebSocketType.AdminAuthentication,
                data: {
                    token: getToken()
                },
                isConnected : true,
            }))
        };

        client.onclose = (e) => {
            if(e.code === 1006) {
                setTimeout(SubmissionStore.open, 5000);
            }
        };

        this.socket = client;
    };

    add = ({body}) => {
        this.send(JSON.stringify(body));
    };

    close = () => {
        if (this.socket) this.socket.close();
        this.socket = null;
    };
}

export default new SubmissionWebSocket();
