import React, {useEffect, useState} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {UserTableTemplate} from "./UserTableTemplate";
import {LoadingOverlay} from "../../../../../common/components/LoadingOverlayMUI";
import {useObserver} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core";
import {Default} from "./Default";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    }
}));

export const UserTable = ({bizGroupId, bizClassId, homeworkId, page, handlePageChange}) => {
    const classes = useStyles();

    const {tableDataStore} = useStores();
    const {tableDatas} = tableDataStore;

    const [loading, setLoading] = useState(true);
    const [sortModel, setSortModel] = useState([]);

    useEffect(() => {
        setLoading(true);
        tableDataStore.fetchAll({bizGroupId, bizClassId, homeworkId, page, sortModel}).then(() => setLoading(false));
    }, [homeworkId, page])

    const handleSortModelChange = newModel => {
        setLoading(true);
        setSortModel(newModel);
        tableDataStore.fetchAll({bizGroupId, bizClassId, page, homeworkId, sortModel: newModel}).then(()=>setLoading(false));
    }

    return useObserver(() => {
        const {usersSolveInfo, count} = tableDataStore

        return (
            <LoadingOverlay
                active={loading}
                className={classes.root}
            >
                {!loading ? (
                    count !== 0 ? (
                        <UserTableTemplate
                            tableDatas={[...tableDatas]}
                            handlePageChange={handlePageChange}
                            count={count}
                            handleSortModelChange={handleSortModelChange}
                            usersSolveInfo={usersSolveInfo}
                            page={page}
                        />
                    ) : <Default/>
                ) : null}
            </LoadingOverlay>
        )
    });
}
