import React, {useEffect, useState} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import {SimpleVerticalDnD} from "../../../../../common/components/SimpleVerticalDnD";
import {FormControl, makeStyles} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import {LevelType} from "../../../../../common/components/level/LevelType";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import {updateOrder} from "../../../../../utils/immutableLodash";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ProblemModel} from "../../../../../common/modules/problem/model/ProblemModel";

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    problemText: {
        width: 250
    },
    dialogPaper: {
        maxWidth: '90vw',
        width: '90vw',
        overflowX: 'hidden',
        paddingBottom: theme.spacing(8)
    },
    deleteButton: {
        minWidth: 30,
        width: 30,
        padding: "3px 5px"
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    addButton: {
        marginRight: theme.spacing(2.5),
        backgroundColor: 'green'
    }
}))

export const ProblemOldEdit = ({homeworkProblems, handleChange}) => {
    const {problemStore} = useStores();
    const classes = useStyles();

    useEffect(() => {
        problemStore.fetchAll({});
    }, [])

    const handleDnD = (items) => {
        handleChange({target: {
                name: 'problems',
                value: items
            }})
    }

    const updateProblems = (problem, e) => {
        const {name, value} = e.target;

        return homeworkProblems.map(item =>
            item.order === problem.order ? {...problem, [name]: value} : item
        );
    }

    const handleProblemChange = (problem, e) => {
        const newProblems = updateProblems(problem, e);

        handleChange({target: {
                name: 'problems',
                value: newProblems
            }});
    }

    const ProblemList = ({item}) => {
        const [score, setScore] = useState(item.score);
        const [mouseEnter, setMouseEnter] = useState(false);

        const numberValidator = () => {
            return !Number.isInteger(Number(score))
        }

        const validateAndChange = (item, e) => {
            if(numberValidator())
                setScore(item.score)
            else
                handleProblemChange(item, e);
        }

        const handleDelete = () => {
            const filterProblems = homeworkProblems.filter(problem => problem.id !== item.id);
            const newProblems = updateOrder(filterProblems);

            handleChange({target: {
                    name: 'problems',
                    value: newProblems
                }});
        }

        const handleMouseEnter = () => {
            setMouseEnter(true);
        }

        const handleMouseLeave = () => {
            setMouseEnter(false);
        }

        const handleNameChange = (e, newValue) => {
            if(!newValue)
                return;

            problemStore.getProblemsIntro({problemId: newValue.id}).then((isIntro)=>{
                const newProblems = homeworkProblems.map(problem => (problem.order !== item.order ? problem : {
                    ...problem,
                    id: newValue.id,
                    name: newValue.name,
                    isIntro,
                }));

                handleChange({target: {
                        name: 'problems',
                        value: newProblems
                }});
            })
        }

        return useObserver(() => {
            const {problems} = problemStore;

            return (
                <Box className={classes.list}
                     key={`problemList-${item.name}`}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}
                >
                    <Autocomplete
                        style={{width: 300}}
                        value={item}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name || ''}
                        options={[item, ...problems]}
                        onChange={handleNameChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Problem"
                                variant="outlined"
                            />
                        )}
                    />
                    <FormControl>
                        <InputLabel>Level</InputLabel>
                        <Select
                            name='level'
                            value={item.level}
                            onChange={(e) => handleProblemChange(item, e)}
                        >
                            {Object.keys(LevelType).map(menu =>
                                <MenuItem key={`${item.name}prob-${menu}`} value={menu}> {menu} </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        name='score'
                        label='Score'
                        error={numberValidator()}
                        value={score}
                        onChange={(e) => setScore(e.target.value)}
                        onBlur={(e) => validateAndChange(item, e)}
                    />
                    {
                        item.isIntro ? "기본문제" : ""
                    }
                    {mouseEnter ? <Button onClick={handleDelete} disableRipple variant='contained'
                                          className={classes.deleteButton}>
                            X
                        </Button> :
                        <Box width={30}/>}
                </Box>
            )
        })
    }

    const handleAdd = () => {
        const addedProblems = homeworkProblems.concat([new ProblemModel()]);
        const newProblems = updateOrder(addedProblems);
        handleChange({target: {
                name: 'problems',
                value: newProblems
            }});
    }

    return useObserver(() => {
        return (
            <Box>
                <Box className={classes.content}>
                    <IconButton className={classes.addButton} onClick={handleAdd}>
                        <AddIcon />
                    </IconButton>
                    <SimpleVerticalDnD items={homeworkProblems} handleDnD={handleDnD} dnDItem={ProblemList}
                                       handleProblemChange={handleProblemChange}/>
                </Box>
            </Box>)
    })
}
