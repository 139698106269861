import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../utils/immutableLodash";
import {renameKeys} from "../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../utils/camelize";
import {snakelizeKeys} from "../../../../utils/snakelize";


const problemConvertMap = {

};

export class ProblemModel {
    id = 0;
    name = '';
    missionId = 0;
    level = 'Easy';
    score = 0;
    order = 0;
    conceptId = 0;
    studyUnitId = 0;
    missionOrder = 0;
    conceptOrder = 0;
    studyUnitOrder = 0;
    problemOrder = 0;
    isIntro = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const problem = pickCls(this, renameKeys(camelizeKeys(data), problemConvertMap));
        set(this, problem);
    }
}

const problemDAOConvertMap = {

};

export class ProblemDAOModel {
    id = 0;
    missionId = 0;
    level = '';
    score = 0;
    order = 0;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const problem = pickCls(this, renameKeys(snakelizeKeys(data), problemDAOConvertMap));
        set(this, problem);
    }
}
