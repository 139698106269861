import React, {useEffect} from "react";
import {useStores} from "../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import {BizGroupsTemplate} from "./BizGroupsTemplate";

export const BizGroupsContainer = () => {
    const { bizGroupStore } = useStores();

    useEffect(() => {
        bizGroupStore.fetchAll();
    }, [])

    return useObserver(() => {
        const { bizGroups } = bizGroupStore;

        if(!(bizGroups && bizGroups.length)) {
            return null;
        }

        return <BizGroupsTemplate bizGroups={bizGroups} />
    })
}