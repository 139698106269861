import {GET} from "../../../../utils/api";
import {getArrayQueryString, makeQueryString} from "../../../../utils/makeQueryString";


class ProblemRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({problemSetting}) {
        const queryString = makeQueryString(problemSetting);
        return GET(`/problems/?${queryString}`);
    }

    findProblems({body}) {
        const queryString = getArrayQueryString('problems', body)
        return GET(`/find-problems/?${queryString}`);
    }

    getProblemsIntro({problemId}) {
        return GET(`/get-problems-intro/${problemId}/`);
    }
}

// 싱글톤으로 리턴
export default new ProblemRepository();
