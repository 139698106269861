import {useHistory} from "react-router-dom";
import {useStores} from "../../../../../common/hooks/UseStores";
import React, {useEffect, useState} from "react";
import {useObserver} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core";
import {HomeworkTemplate} from "./HomeworkTemplate";
import {updateOrder} from "../../../../../utils/immutableLodash";
import {HomeworkDAOModel} from "../HomeworkList/modules/model/HomeworkModel";
import {snackActions} from "../../../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../../../utils/errorHandler";
import {LoadingOverlay} from "../../../../../common/components/LoadingOverlayMUI";

const useStyles = makeStyles(theme => ({
    loadingOverlay: {
        minHeight: "500px",
    },
}))

export const HomeworkContainer = (props) => {
    const classes = useStyles();
    const {bizGroupId, bizClassId, homeworkId} = props;

    const history = useHistory();
    const { homeworkStore } = useStores();

    const [editing, setEditing] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [oldVersion, setOldVersion] = useState(false);

    useEffect(() => {
        setLoading(true);
        homeworkStore.fetch({bizGroupId, bizClassId, homeworkId}).then(()=>{
            setLoading(false);
        });
        return () => {
            homeworkStore.clearHomework();
        }
    }, []);

    const redirectToHomeworks = () => {
        handleCancel();
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks`);
    }

    const deleteAndRedirect = () => {
        handleDelete(homeworkId);
        setDeleteOpen(false);
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks`);
    }

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    }

    const handleClose = () => {
        setDeleteOpen(false);
    }

    const handleEdit = () => {
        setEditing(true);
    }

    const handleCancel = () => {
        setEditing(false);
    }

    const handleToggleVersion = () => {
        if(oldVersion) setOldVersion(false);
        else setOldVersion(true);
    }

    const handleUpdate = (inputs) => {
        const newInputs = {...inputs, problems: updateOrder(inputs.problems)};
        const newHomework = new HomeworkDAOModel(newInputs);
        if(inputs.id) {
            homeworkStore.updateHomework({bizGroupId, bizClassId, homeworkId: inputs.id, body: newHomework}).then(
                () => {
                    snackActions.success("정상적으로 수정되었습니다.");
                    setEditing(false);
                }
            ).catch(
                (error) => {
                    errorToast(error);
                }
            );
        } else {
            homeworkStore.addHomework({bizGroupId, bizClassId, body: newHomework}).then(
                () => {
                    snackActions.success("정상적으로 추가되었습니다.");
                    setEditing(false);
                }
            ).catch(
                (error) => {
                    errorToast(error);
                }
            );
        }
    }

    const handleDelete = (homeworkId) => {
        homeworkStore.deleteHomework({bizGroupId, bizClassId, homeworkId}).then(
            () => {
                snackActions.success("정상적으로 삭제되었습니다.")
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );
    }

    return useObserver(() => {
        const {homework, checkedProblems} = homeworkStore;

        return (
            <LoadingOverlay
                active={loading}
                spinner
                text="과제를 불러오는 중입니다."
                className={classes.loadingOverlay}
            >
                {homework ? (
                    <HomeworkTemplate
                        bizGroupId={bizGroupId}
                        bizClassId={bizClassId}
                        homework={homework}
                        redirectToHomeworks={redirectToHomeworks}
                        deleteAndRedirect={deleteAndRedirect}
                        handleDeleteOpen={handleDeleteOpen}
                        handleClose={handleClose}
                        handleEdit={handleEdit}
                        handleCancel={handleCancel}
                        editing={editing}
                        handleUpdate={handleUpdate}
                        deleteOpen={deleteOpen}
                        oldVersion={oldVersion}
                        handleToggleVersion={handleToggleVersion}
                        checkedProblems={checkedProblems}
                    />
                ) : null}
            </LoadingOverlay>
        )
    })
}
