import {GET, PATCH, POST} from "../../../../utils/api";


class ReplyRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({questionId, commentId}) {
        return GET(`/discussions/${questionId}/comments/${commentId}/replies/`);
    }

    addReply({questionId, commentId, body}) {
        return POST(`/discussions/${questionId}/comments/${commentId}/replies/`, body);
    }

    updateReply({questionId, commentId, replyId, body}) {
        return PATCH(`/discussions/${questionId}/comments/${commentId}/replies/${replyId}/`, body);
    }
}

// 싱글톤으로 리턴
export default new ReplyRepository();
