import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import {useStores} from "../../../../common/hooks/UseStores";
import {observer} from "mobx-react-lite";
import {DashGraph} from "./DashGraph/DashGraph";
import {UserTable} from "./UserTable/UserTable";
import {HomeworkMenu} from "./components/HomeworkMenu";
import {QuestionList} from "./QuestionList/QuestionList";
import {getItem, setItem} from "../../../../utils/localStorage";
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {searchParamsToObject} from "../../../../utils/url";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection:' row',
        height: '100vh',
        width: 'calc(100vw - 64px)'
    },
    left: {
        display: 'flex',
        flexDirection:' column',
    },
    graphWrapper: {
        width: 1000,
        height: 250,
        marginTop: 30
    },
    tableWrapper: {
        height: 'calc(100% - 250px)'
    },
    right: {
        display: 'flex',
        flexDirection:' column',
        alignItems: 'flex-end',
        width: 'calc(100vw - 1064px)'
    },
}))

const getBizHomeworkKey = (bizGroupId, bizClassId) => {
    return `bizGroup${bizGroupId}-bizClass${bizClassId}-homework`;
}

export const Dashboard = observer(({bizGroupId, bizClassId, homeworkId}) => {
    const BIZHOMEWORKKEY = getBizHomeworkKey(bizGroupId, bizClassId);

    const classes = useStyles();
    const {homeworkStore} = useStores();
    const {homeworks} = homeworkStore;

    const history = useHistory();
    const location = useLocation();

    const params = searchParamsToObject(location.search);
    const { page } = params;


    const [selectedPage, setSelectedPage] = useState(page || '1');

    const handleChange = (newHomeworkId) => {
        setSelectedPage('1');
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/dashboards/${newHomeworkId}`);
    }

    const handlePageChange = (newPage) => {
        setSelectedPage(newPage)
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/dashboards/${homeworkId}?page=${newPage}`)
    }

    useEffect(() => {
        if(!(homeworks && homeworks.length)) {
            homeworkStore.fetchAll({bizGroupId, bizClassId, page: 0, pageSize: 1000});
        }
    }, [bizGroupId, bizClassId])

    useEffect(() => {
        setItem(BIZHOMEWORKKEY, homeworkId);
    }, [homeworkId])

    return (
        <Box className={classes.root}>
            <Box className={classes.left}>
                <Box className={classes.graphWrapper}>
                    <DashGraph bizGroupId={bizGroupId} bizClassId={bizClassId} homeworkId={homeworkId} />
                </Box>
                <Box className={classes.tableWrapper}>
                    <UserTable bizGroupId={bizGroupId} bizClassId={bizClassId} homeworkId={homeworkId} page={selectedPage} handlePageChange={handlePageChange}/>
                </Box>
            </Box>
            <Box className={classes.right}>
                <HomeworkMenu homeworks={[...homeworks]} homeworkId={homeworkId} handleChange={handleChange} />
                <QuestionList bizGroupId={bizGroupId} bizClassId={bizClassId} homeworkId={homeworkId} />
            </Box>
        </Box>
    )
})


const EmptyHomework = ({bizGroupId, bizClassId}) => {
    const BIZHOMEWORKKEY = getBizHomeworkKey(bizGroupId, bizClassId);

    const {homeworkStore} = useStores();

    const classes = useStyles();

    const match = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        const localHomeworkId = Number(getItem(BIZHOMEWORKKEY));

        const {homeworks, bizGroupId: _bizGroupId, bizClassId: _bizClassId} = homeworkStore;

        if((_bizGroupId === bizGroupId) && (_bizClassId === bizClassId) && homeworks && homeworks.length){
            if(homeworks.some(homework => homework.id === localHomeworkId)){
                history.push(`${match.url}/${localHomeworkId}`);
            } else {
                history.push(`${match.url}/${homeworks[0].id}`);
            }

            return () => {};
        }

        homeworkStore.fetchAll({bizGroupId, bizClassId, page: 0, pageSize: 1000}).then((_homeworks) => {
            if(_homeworks && _homeworks.length){
                if(_homeworks.some(__homework => __homework.id === localHomeworkId)){
                    history.push(`${match.url}/${localHomeworkId}`);
                } else {
                    history.push(`${match.url}/${_homeworks[0].id}`);
                }
            }
        });
    }, [bizGroupId, bizClassId])

    return (
        <Box className={classes.root}>
            숙제가 존재하지 않습니다.
        </Box>
    )
}


export const DashboardWithRouter = ({bizGroupId, bizClassId}) => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                render={() => (
                    <EmptyHomework bizClassId={bizClassId} bizGroupId={bizGroupId} />
                )}
            />
            <Route
                path={`${match.url}/:homeworkId`}
                render={({match: _match}) =>
                    <Dashboard bizGroupId={bizGroupId} bizClassId={bizClassId} homeworkId={_match.params.homeworkId} />
                }
            />
        </Switch>
    )
}
