import React, {useEffect, useState} from "react";
import {useObserver} from "mobx-react-lite";
import {useStores} from "../../../../../common/hooks/UseStores";
import {DashGraphTemplate} from "./DashGraphTemplate";

export const DashGraph = ({bizGroupId, bizClassId, homeworkId}) => {
    const { graphDataStore } = useStores();

    const [popupOpen, setPopupOpen] = useState(false);
    const [activeLabel, setActiveLabel] = useState(null);

    useEffect(() => {
        graphDataStore.fetchAll({bizGroupId, bizClassId, homeworkId});
    }, [bizClassId, homeworkId])

    useEffect(() => {
        const id = setInterval(() => {
            graphDataStore.fetchAll({bizGroupId, bizClassId, homeworkId});
        }, 10000);
        return () => clearInterval(id);
    }, [bizClassId, homeworkId]);

    const handleClick = (e) => {
        if(!e) return
        setActiveLabel(e.activeLabel);
        setPopupOpen(true);
    }

    const handleClose = (e) => {
        setActiveLabel(null);
        setPopupOpen(false);
    }

    return useObserver(() => {
        const { graphDatas } = graphDataStore;

        return (
            <DashGraphTemplate
                data={[...graphDatas]}
                handleClick={handleClick}
                handleClose={handleClose}
                popupOpen={popupOpen}
                activeLabel={activeLabel}
            />
        )
    })
}
