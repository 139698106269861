import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../utils/immutableLodash";
import {renameKeys} from "../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../utils/camelize";
import {snakelizeKeys} from "../../../../utils/snakelize";
import moment from "moment";


const replyConvertMap = {

};

export class ReplyModel {
    id = 0;
    content = '';
    username = '';
    createdAt = '';
    isAnonymous = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(this, renameKeys(camelizeKeys(data), replyConvertMap));
        reply.createdAt = moment(reply.createdAt, moment.ISO_8601).format('YY/MM/DD hh:mm:ss a')
        set(this, reply);
    }
}

const replyDAOConvertMap = {

};

export class ReplyDAOModel {
    content = ''

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(this, renameKeys(snakelizeKeys(data), replyDAOConvertMap));
        set(this, reply);
    }
}
