import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import {DataGrid} from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import {errorToast} from "../../../../../utils/errorHandler";
import Typography from "@material-ui/core/Typography";
import AlertDialog from "../../../../../common/components/dialog/AlertDialog";
import parsePhoneNumber from 'libphonenumber-js'

const useStyles = makeStyles(theme => ({
    dialog: {

    },
    paper: {
        width: '1000px',
        height: '800px',
        maxWidth: '1000px',
    },
    dialogPaper: {
        padding: theme.spacing(5)
    }
}))

export const BulkUpload = ({handleBulkUpdate, failed, failedOpen, handleFailedClose}) => {
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);

    const classes = useStyles();

    const predefinedRow = ['username', 'email', 'phoneNumber']

    const handleClose = () => {
        setOpen(false);
        setRows([]);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const checkValid = (cellsInfo) => {
        return Object.values(cellsInfo).reduce((acc, curr) => {
            return acc && predefinedRow.some(row => row === curr)
        }, true)
    }

    const handlePaste = (e) => {
        const text = e.clipboardData.getData('text');

        const lines = text.split('\n');
        const cellsInfo = {};

        lines[0].split('\t').forEach((cell, i) => cellsInfo[i] = cell.trim());

        if(!checkValid(cellsInfo)) {
            errorToast({message: 'field 값은 \'username\', \'phoneNumber\', \'email\'만 가능합니다.'})
            return;
        }

        lines.shift();

        const newRows = lines.map((line, i) => {
            const cells = line.split('\t');

            return (cells.reduce((acc, curr, j) => {
                if(cellsInfo[j] === 'phoneNumber') {
                    return {
                        ...acc,
                        [cellsInfo[j]]: parsePhoneNumber(curr.trim(), 'KR').number
                    }
                }
                return {
                    ...acc,
                    [cellsInfo[j]]: curr.trim(),
                }
            }, {id: i}))
        });

        setRows(newRows);
    }

    const columns = [
        {field: 'username', headerName: 'Username', width: 200, sortable: false, filterable: false},
        {field: 'email', headerName: 'E-mail', width: 200, sortable: false, filterable: false},
        {field: 'phoneNumber', headerName: 'Phone Number', width: 200, sortable: false, filterable: false},
    ]

    const updateAndClose = () => {
        handleBulkUpdate(rows);
        handleClose();
    }

    return (
        <Box>
           <Button variant='contained' onClick={handleOpen}>
               Bulk Upload
           </Button>
           <Dialog
               open={open}
               onClose={handleClose}
               classes={{
                   paper: classes.paper,
               }}
           >
                <Paper className={clsx(classes.paper, classes.dialogPaper)} >
                    <Box>
                        <Button onClick={updateAndClose}>
                            Submit
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                    {rows.length ?
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            hideFooter
                        /> :
                        <TextField
                            onPaste={handlePaste}
                            variant='outlined'
                            fullWidth
                            rows={50}
                            value={''}
                            autoFocus
                        />}
                </Paper>
           </Dialog>
            <AlertDialog
                open={failedOpen}
                handleClose={handleFailedClose}
                handleConfirm={handleFailedClose}
                title={"등록에 실패한 유저들이 존재합니다."}
                description={failed}
            />
        </Box>
    )
}
