import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {Box, makeStyles} from "@material-ui/core";
import {CustomPagination} from "../../../../../common/components/CustomPagination";
import {useHistory} from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100vh',
        padding: theme.spacing(10),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%'
    },
    table: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    addButton: {
        backgroundColor: 'lightgreen',
        marginBottom: theme.spacing(2.5)
    },
    dialogPaper: {
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
    },
    dataGrid: {
        '& .MuiDataGrid-columnHeaderWrapper' : {
            backgroundColor: 'pink'
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid ##f0f0f0'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid #f0f0f0`,
        },
        '& .MuiDataGrid-row': {
            backgroundColor: '#ffffff',
        },
        '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'pink'
        }
    }
}))

export const QuestionsTemplate = ({bizGroupId, bizClassId, questions, count, loading, handleSettingChange}) => {
    const classes = useStyles();
    const history = useHistory();

    function getRowIndex(params) {
        return `${params.row.resolved ? '✅' : ''} ${params.api.getRowIndex(params.id) + 1
        + params.api.getState().pagination.page * params.api.getState().pagination.pageSize}`;
    }

    const columns = [
        {field: 'number', headerName: '#', width: 50, sortable: false, editable: false,
            valueGetter: getRowIndex
        },
        {field: 'username', headerName: 'User Name', width: 200, sortable: false, editable: false,},
        {field: 'createdAt', headerName: 'Created At', width: 200, sortable: false, editable: false,},
        {field: 'homeworkName', headerName: 'Homework Name', width: 200, sortable: false, editable: false,},
        {field: 'problemName', headerName: 'Problem Name', width: 200, sortable: false, editable: false,},
        {field: 'title', headerName: 'Title', width: 200, sortable: false, editable: false,},
    ]

    const handleClick = (params) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/questions/${params.row.id}`)
    }

    const handleChange = (e) => {
        const {name, checked} = e.target;
        handleSettingChange(name, checked);
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={handleChange}
                            name="showOnlyUnresolved"
                            color="primary"
                        />
                    }
                    label="Show Only Unresolved"
                />
                <Box className={classes.table}>
                    <DataGrid
                        disableColumnMenu
                        pagination
                        pageSize={10}
                        className={classes.dataGrid}
                        columns={columns}
                        rows={questions}
                        autoHeight
                        rowCount={count}
                        loading={loading}
                        paginationMode='server'
                        components={{
                            Pagination: CustomPagination
                        }}
                        onRowClick={handleClick}
                    />
                </Box>
            </Box>
        </Box>
    )
}
