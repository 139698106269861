import {flow, makeAutoObservable} from 'mobx';
import {setItem} from "../../../../utils/localStorage";
import BizClassRepository from "../repository/BizClassRepository";
import {BizClassModel} from "../model/BizClassModel";

const localBizClassKey = (bizGroupId) => {
    return `${bizGroupId}-localBizClassId`;
}

export default class BizClassStore {
    bizClasses = [];
    bizClass = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetch: flow,
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId}) {
        const response = BizClassRepository.findAll({bizGroupId});
        this.bizClasses.replace(response.data.map(bizClass => new BizClassModel(bizClass)))
    }

    *fetch({bizGroupId, bizClassId}) {
        const response = BizClassRepository.find({bizGroupId, bizClassId})
        this.bizClass = new BizClassModel(response.data);
    }
}
