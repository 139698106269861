import React, {useState} from "react";
import {CommentEditor} from "../comment/CommentEditor";
import {useStores} from "../../../../../../common/hooks/UseStores";
import {ReplyDAOModel} from "../../../../../../common/modules/reply/model/ReplyModel";


export const ReplyForm = ({questionId, comment, handleIsReplyFormOpen, onAdd}) => {
    const [content, setContent] = useState("");
    const { replyStore } = useStores();

    const onChange = (e) => {
        setContent(e.target.value);
    }

    const onCancel = () => {
        handleIsReplyFormOpen();
    }

    const onSubmit = () => {
        const newReply = new ReplyDAOModel({content});
        replyStore.addReply({questionId, commentId: comment.id, body: newReply}).then(newReply => onAdd(newReply));

    }

    return <CommentEditor content={content} buttonText="Submit" onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
}
