import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import {
    alpha,
    makeStyles,
    Menu,
    MenuItem, Typography,
} from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
    MissionNameTypeToColorCode,
    MissionNameTypeToExpectedMonth,
} from "./modules/model/CuratedProblemModel"
import {useStores} from "../../../../../common/hooks/UseStores";

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px 0 0 0",
        position: "sticky",
        top: "0",
        zIndex: "1100",
        background: "white",
        [theme.breakpoints.down(600)]: {
            padding: "16px 16px 60px 16px",
            height: "calc(100vh - 48px)",
        },
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        width: 1060,
        alignItems: "center",
        [theme.breakpoints.down(1280)]: {
            width: 800,
        },
        [theme.breakpoints.down(992)]: {
            width: "100%",
        },
    },
    header: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #CDCDCD",
        position: "relative",
        paddingBottom: 7,
        overflow: "hidden",
        width: "100%",
        [theme.breakpoints.down(600)]: {
            justifyContent: "space-between",
        },
    },
    dropdownWrapper: {
        flexDirection: "row",
        padding: "10px 8px",
        alignSelf: "flex-start",
        [theme.breakpoints.down(600)]: {
            padding: "8px 0",
            background: alpha("#000000", 0.05),
            borderRadius: "8px",
        },
    },
    dropdown: {
        background: "transparent",
        borderRadius: "8px",
        padding: "4px 8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#4D4D4D",
        width: 182,
    },
    dropdownTypo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    dropdownMain: {
        fontSize: 10,
        lineHeight: "120%",
        color: "#4D4D4D",
        letterSpacing: "-0.2px",
        fontWeight: 700,
        whiteSpace: "pre-line",
        textTransform: "uppercase",
    },
    dropdownCopy: {
        fontSize: 12,
        lineHeight: "133%",
        color: "#4D4D4D",
        letterSpacing: "-0.2px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
    triangle: {
        width: 0,
        height: 0,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderTop: "4px solid #4D4D4D",
    },
    menu: {
        width: 200,
        borderRadius: "8px",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.08)",
    },
    menuItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 16px",
        background: "#FFFFFF",
        color: "#4D4D4D",
        textTransform: "uppercase",
    },
    selectedItem: {
        background: "#F5F5F7",
        color: "#000000",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    number: {
        fontSize: 10,
        lineHeight: "120%",
        letterSpacing: "-0.2px",
        fontWeight: 700,
        whiteSpace: "pre-line",
        marginLeft: "15px",
    },
    menuMain: {
        fontSize: 10,
        lineHeight: "120%",
        letterSpacing: "-0.2px",
        fontWeight: 700,
        whiteSpace: "pre-line",
    },
    menuCaption: {
        fontSize: 12,
        lineHeight: "133%",
        letterSpacing: "-0.2px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
    selectedLock: {
        color: "#4D4D4D",
    },
    unselectedLock: {
        color: "#808080",
    },
    comingSoon: {
        borderRadius: "22px",
        background: "#EBEBEB",
        padding: "3px 8px",
        color: "#1A1A1A",
        letterSpacing: "-0.2px",
        fontSize: 10,
        lineHeight: "120%",
    },
    problemDetail: {
        marginLeft: "180px",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down(680)]: {
            marginLeft: "auto",
        },
    },
    mainText: {
        fontFamily: "Pretendard, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
        fontSize: "20px",
        fontWeight: "700",
    },
    subText: {
        marginTop: "2px",
        fontSize: "12px",
        color: "#808080",
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
    },
}));

export const MissionSelectForm = (props) => {
    const classes = useStyles();

    const { missionStore } = useStores();

    const {selectedMission, onSelect} = missionStore;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        missionStore.fetchAll();
    }, []);

    const isAvailableMission = (mission) => {return mission.usable;}

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickItem = (mission) => {
        if (isAvailableMission(mission)) {
            onSelect(mission);
        }
        handleClose();
    };

    return useObserver(() => {
        const { homework } = props;
        const { missions } = missionStore;

        const getAdornment = (_mission) => {
            if (!_mission.usable) {
                return (
                    <Box className={classes.comingSoon}>
                        {`${
                            MissionNameTypeToExpectedMonth[_mission.name]
                        }월 출시 예정`}
                    </Box>
                );
            }
            if (!isAvailableMission(_mission)) {
                return (
                    <LockOutlinedIcon
                        className={
                            selectedMission.id === _mission.id
                                ? classes.selectedLock
                                : classes.unselectedLock
                        }
                    />
                );
            }
            return null;
        };

        return (
            <Box className={classes.root}>
                <Box className={classes.wrapper}>
                    <Box className={classes.header}>
                        <Box className={classes.dropdownWrapper}>
                            <Button
                                className={classes.dropdown}
                                onClick={handleClick}
                            >
                                <Box className={classes.dropdownTypo}>
                                    <Box className={classes.dropdownMain}>
                                        {`${selectedMission && selectedMission.name}`}
                                    </Box>
                                    <Box className={classes.dropdownCopy}>
                                        {`${selectedMission && selectedMission.copy}`}
                                    </Box>
                                </Box>
                                <Box className={classes.triangle} />
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                keepMounted
                                classes={{
                                    paper: classes.menu,
                                }}
                            >
                                {missions.map((mission, i) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => handleClickItem(mission)}
                                        selected={
                                            selectedMission.id === mission.id
                                        }
                                        disabled={!mission.usable}
                                        key={`mission-menu-${i}`}
                                        classes={{
                                            selected: classes.selectedItem,
                                        }}
                                        style={{
                                            borderLeft: `2px solid ${
                                                MissionNameTypeToColorCode[
                                                    mission.name
                                                    ]
                                            }`,
                                        }}
                                    >
                                        <Box className={classes.row}>
                                            <Box className={classes.column}>
                                                <Box className={classes.menuMain}>
                                                    {mission.name}
                                                </Box>
                                                <Box className={classes.menuCaption}>
                                                    {mission.copy}
                                                </Box>
                                            </Box>
                                            <Box className={classes.number}>
                                                {homework && homework.problems && homework.problems.filter(problem => problem.missionId === mission.id).length}
                                            </Box>
                                        </Box>
                                        {getAdornment(mission)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box className={classes.problemDetail}>
                            <Typography className={classes.mainText}>
                                {homework && homework.problems && homework.problems.length}
                            </Typography>
                            <Typography className={classes.subText}>
                                선택한 총 문제 수
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    });
};
