import {toJS} from "mobx";

const clone = (obj) => {
    if (obj === null || typeof(obj) !== 'object')
        return obj;

    let copy = obj.constructor();

    for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
            copy[attr] = obj[attr];
        }
    }

    return copy;
};

export const renameKeys = (object, changes) => {
    object = toJS(object); // avoid using mobx object when rename keys
    if (!changes || typeof changes !== 'object') {
        return object
    }

    if (Array.isArray(object)) {
        const newArray = [];
        for (let i = 0; i < object.length; i++) {
            newArray.push(renameKeys(object[i], changes));
        }
        return newArray
    } else {
        if (typeof object !== 'object') {
            return object;
        }
        let copy = clone(object);

        for (let key in changes) {
            if (typeof changes[key] === 'object') {
                if (copy.hasOwnProperty(key)) {
                    copy[key] = renameKeys(copy[key], changes[key]);
                    continue
                }
            }

            if (copy.hasOwnProperty(key)) {
                let temp = copy[key];

                copy[changes[key]] = temp;
                delete copy[key];
            }
        }
        return copy
    }
};
