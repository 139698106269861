import {flow, makeAutoObservable} from 'mobx';
import GroupUserRepository from "../repository/GroupUserRepository";
import {GroupUserModel} from "../model/GroupUserModel";

export default class GroupUserStore {
    users = [];
    groupUsers = [];
    userCount = 0;
    groupUserCount = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetchAllGroupUsers: flow,
            updateGroupUsers: flow,
            updateBulkGroupUsers: flow,
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId, bizClassId, page, setting}) {
        const response = yield GroupUserRepository.findAll({bizGroupId, bizClassId, page, setting});
        this.users.replace(response.data.results.map(user => new GroupUserModel(user)));
        this.userCount = response.data.count;
    }

    *fetchAllGroupUsers({bizGroupId, bizClassId, page, setting}) {
        const response = yield GroupUserRepository.findAllGroupUsers({bizGroupId, bizClassId, page, setting});
        this.groupUsers.replace(response.data.results.map(user => new GroupUserModel(user)));
        this.groupUserCount = response.data.count;
    }

    *updateGroupUsers({bizGroupId, bizClassId, body, setting}) {
        const response = yield GroupUserRepository.updateGroupUsers({bizGroupId, bizClassId, body});
        this.fetchAll({bizGroupId, bizClassId, page: 0, setting});
        this.groupUsers.replace(response.data.results.map(user => new GroupUserModel(user)));
        this.groupUserCount = response.data.count;
    }

    *updateBulkGroupUsers({bizGroupId, bizClassId, body, setting}) {
        const response = yield GroupUserRepository.updateBulkGroupUsers({bizGroupId, bizClassId, body});
        this.fetchAll({bizGroupId, bizClassId, page: 0, setting});

        if(response.status === 200) {
            this.groupUsers.replace(response.data.results.map(user => new GroupUserModel(user)));
            this.groupUserCount = response.data.count;
        } else if(response.status === 207) {
            this.groupUsers.replace(response.data[0].results.map(user => new GroupUserModel(user)));
            this.groupUserCount = response.data[0].count;
            return response.data[1];
        }
    }
}
