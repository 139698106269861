import {GET} from "../../../../../../../utils/api";

class CuratedProblemRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    fetchAll() {
        return GET(`/missions/`);
    }

    fetchModel(missionId) {
        return GET(`/missions/${missionId}/concepts/`);
    }
}

// 싱글톤으로 리턴
export default new CuratedProblemRepository();
