import React from "react";
import {Switch, Route, Redirect, useRouteMatch} from "react-router-dom";
import {DashboardWithRouter} from "./Dashboard/Dashboard";
import {Users} from "./Users/Users";
import {Homeworks} from "./Homeworks/Homeworks";
import {Questions} from "./Questions/Questions";
import {Submission} from "./Submission/Submission";

export const MainContainer = ({bizGroupId, bizClassId}) => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.url}`} render={() => <Redirect to={`${match.url}/dashboards`} />} />
            <Route path={`${match.url}/dashboards`} render={() => <DashboardWithRouter bizGroupId={bizGroupId} bizClassId={bizClassId} />} />
            <Route path={`${match.url}/users`} render={()=> <Users bizGroupId={bizGroupId} bizClassId={bizClassId} />} />
            <Route path={`${match.url}/homeworks`} render={()=> <Homeworks bizGroupId={bizGroupId} bizClassId={bizClassId} />} />
            <Route path={`${match.url}/questions`} render={()=> <Questions bizGroupId={bizGroupId} bizClassId={bizClassId} />} />
            <Route path={`${match.url}/submission`} render={()=> <Submission bizGroupId={bizGroupId} bizClassId={bizClassId} />} />
            <Redirect to="/not-found" />
        </Switch>
    )
}
