import React, {useEffect, useState} from "react";
import {useObserver} from "mobx-react-lite";
import {alpha, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {DataGrid} from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import {CustomPagination} from "./CustomPagination";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    root : {
        margin: 0,
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: props => props.listWidth,
        height: props => props.listHeight,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    pagination: {
        display: 'flex',
    },
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.8),
        },
        marginLeft: 0,
        width: '100%',
        height: 40
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: 40,
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

const CustomList = ({count, items, id, handlePageChange, loading, checked, handleSelectionChange, columns, pageSize, listWidth, listHeight, handleSearch,}) => {
    const classes = useStyles({listWidth, listHeight});

    const [search, setSearch] = useState("");

    const handleChange = (e) =>{
        const {value} = e.target;

        handleSearch(id, value);
        setSearch(value);
    }

    return useObserver(() => (
        <Box className={classes.root}>
            <Box className={classes.search}>
                <Box className={classes.searchIcon}>
                    <SearchIcon />
                </Box>
                <InputBase
                    placeholder="Search…"
                    value={search}
                    onChange={handleChange}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Box>
            <Box className={classes.list}>
                <DataGrid
                    disableColumnMenu
                    checkboxSelection
                    components={{
                        Footer: CustomPagination,
                    }}
                    componentsProps={{
                        footer: {selectionModel: checked[id]},
                    }}
                    rows={items}
                    columns={columns}
                    rowCount={count}
                    pageSize={pageSize}
                    paginationMode='server'
                    onPageChange={(page) => handlePageChange(id, page)}
                    loading={loading[id]}
                    selectionModel={checked[id]}
                    onSelectionModelChange={(newSelection) => handleSelectionChange(id, newSelection)}
                />
            </Box>
        </Box>
    ))
};

CustomList.defaultProps = {
    disableList: []
}

export const CustomTransferList = (props) => {
    const classes = useStyles(props);

    const [checked, setChecked] = useState({
        left: [],
        right: []
    });

    const handleSelectionChange = (id, newSelection) => {
        setChecked({...checked, [id]: newSelection});
    }

    const {
        left,
        right,
        columns,
        leftCount,
        rightCount,
        handlePageChange,
        loading,
        handleUpdate,
        pageSize,
        listWidth,
        listHeight,
        disableList,
        handleSearch,
    } = props;

    const handleCheckedRight = () => {
        handleUpdate(checked.left);
        setChecked({
            ...checked,
            left: []
        })
    }

    const handleCheckedLeft = () => {
        handleUpdate(checked.right);
        setChecked({
            ...checked,
            right: []
        })
    }

    return useObserver(() => {
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>
                    <CustomList
                        id='left'
                        items={left}
                        count={leftCount}
                        handlePageChange={handlePageChange}
                        loading={loading}
                        checked={checked}
                        handleSelectionChange={handleSelectionChange}
                        columns={columns}
                        pageSize={pageSize}
                        listWidth={listWidth}
                        listHeight={listHeight}
                        disableList={disableList}
                        handleSearch={handleSearch}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={checked.left.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={checked.right.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <CustomList
                        id='right'
                        items={right}
                        count={rightCount}
                        handlePageChange={handlePageChange}
                        loading={loading}
                        checked={checked}
                        handleSelectionChange={handleSelectionChange}
                        columns={columns}
                        pageSize={pageSize}
                        listWidth={listWidth}
                        listHeight={listHeight}
                        handleSearch={handleSearch}
                    />
                </Grid>
            </Grid>
        )
    });
}

CustomTransferList.defaultProps = {
    pageSize: 10,
    listWidth: 700,
    listHeight: 650,
    disableList: []
}
