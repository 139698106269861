import {GET} from "../../../../../../utils/api";

class SubmissionRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, pageSize, page}) {
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/group-submissions/?page_size=${pageSize}&page=${page+1}`);
    }

    getFailedTestcaseUrl({problemId, submissionId, resultId, isInputFile}) {
        return GET(`/problems/${problemId}/submissions/${submissionId}/results/${resultId}/failed-case/${isInputFile ? '?input=true' : '?input=false'}`);
    }
}

// 싱글톤으로 리턴
export default new SubmissionRepository();
