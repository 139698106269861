export const makeQueryString = (obj) => {
    if(!obj)
        return ""

    return Object.keys(obj)
        .reduce((acc, curr) =>
            (obj[curr] ? acc + `&${curr}=${obj[curr]}` : acc), "")
}

export const makeSortString = (sortModel) => {
    if(!(sortModel && sortModel.length))
        return ""

    return sortModel.reduce((acc, curr) => acc + `&${curr.field}=${curr.sort}`, "")
}

export const getArrayQueryString = (key, arr) => {
    if(!arr.length){
        return "";
    }
    return arr.reduce((acc, curr) => acc + curr + "+", `&${key}=`).slice(0, -1);
}
