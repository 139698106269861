import React, {useEffect, useState} from "react";
import {useStores} from "../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import ChannelService from "../../../common/components/ChannelService";
import {LoginTemplate} from "./LoginTemplate";

export const LoginContainer = () => {
    const {authStore} = useStores();
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });

    useEffect(() => {
        ChannelService.boot({
            "pluginKey": "a9562cfd-5bfb-4a7d-945f-b2e857e3146c" //please fill with your plugin key
        })

        return () => ChannelService.shutdown();
    }, [])

    const login = ({username, password}) => {
        authStore.login({username, password});
    }

    const onSubmit = (e) => {
        login(inputs);
    }

    const onChange = (e) => {
        const {id, value} = e.target;
        setInputs({
            ...inputs,
            [id]: value
        });
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter')
            login(inputs);
    }

    return useObserver(() => {
        return <LoginTemplate inputs={inputs} onChange={onChange} onSubmit={onSubmit} onKeyPress={onKeyPress} />
    })
}
