import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Markdown from "../../../../../../common/components/Markdown";
import Box from "@material-ui/core/Box";
import {CommentEditor} from "../comment/CommentEditor";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import CreateIcon from "@material-ui/icons/Create";
import {useStores} from "../../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import {ReplyModel} from "../../../../../../common/modules/reply/model/ReplyModel";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(1)
    },
    replyMeta: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.text.light
    },
    replyMetaUser: {
        display: "flex"
    },
    replyMetaItem: {
        marginLeft: theme.spacing(1)
    },
    replyMain: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1)
    },
    replyFooter: {
        display: "flex",
        marginTop: theme.spacing(1),
        color: theme.palette.secondary.main
    },
    replyFooterItem: {
        display: "flex",
        marginLeft: theme.spacing(1),
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.secondary.light
        }
    },
    replyFooterItemText: {
        marginTop: 4,
        marginLeft: 3
    }
}))

const _ReplyViewForm = ({content}) => {
    return (
        <Box>
            <Markdown source={content}/>
        </Box>
    )
};

const _ReplyEditForm = ({content, onSubmit, onChange, onCancel}) => {
    return (
        <CommentEditor content={content} buttonText="Update" onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
    )
};

export const Reply = ({reply, questionId, comment}) => {
    const classes = useStyles();
    const {authStore ,replyStore} = useStores();

    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(reply.content);

    const onChange = (e) => {
        setContent(e.target.value);
    }

    const onCancel = () => {
        setContent(reply.content);
        setIsEditing(false);
    }

    const onSubmit = () => {
        const newReply = new ReplyModel({content})
        replyStore.updateReply({questionId, commentId: comment.id, replyId: reply.id, body: newReply});
        setIsEditing(false);
    }

    const handleIsEditing = () => {
        setIsEditing(prev => !prev);
    }

    return useObserver(() => {
        const {currUser} = authStore;

        return (
            <Box className={classes.root}>
                <Box className={classes.replyMeta}>
                    <Box className={classes.replyMetaUser}>
                        <SvgIcon>
                            <path d="M9 8c1.66 0 2.99-1.34 2.99-3S10.66 2 9 2C7.34 2 6 3.34 6 5s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V16h14v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                        </SvgIcon>
                        <Typography variant="caption"> {reply.username} </Typography>
                    </Box>
                    <Typography variant="caption" className={classes.replyMetaItem}>
                        <Moment format="YYYY년 MM월 DD일 HH:mm:ss" parse={"YY/MM/DD hh:mm:ss a"}>
                            {reply.createdAt}
                        </Moment>
                    </Typography>
                </Box>
                <Box className={classes.replyMain}>
                    {isEditing ?
                        <_ReplyEditForm content={content} onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} /> :
                        <_ReplyViewForm content={content} />
                    }
                    <Box className={classes.replyFooter}>
                        {currUser.username === reply.username ? (
                            <Box className={classes.replyFooterItem} onClick={handleIsEditing}>
                                <CreateIcon />
                                <Typography className={classes.replyFooterItemText} variant="caption"> Edit </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        )
    });
}
