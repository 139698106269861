import React from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import {Comment} from "./Comment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    }
}))

export const CommentReplyList = ({questionId, comments}) => {
    const classes = useStyles();

    if(!(comments && comments.length))
        return null;

    return (
            <Paper className={classes.root}>
                {
                    comments.map(item => (
                        <Comment questionId={questionId} comment={item} key={item.id} />
                    ))
                }
            </Paper>
    )
}
