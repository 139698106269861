import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../../../utils/camelize";
import {ProblemModel} from "../../../../../../../common/modules/problem/model/ProblemModel";
import moment from "moment";
import {snakelizeKeys} from "../../../../../../../utils/snakelize";

const homeworkConvertMap = {
};

export class HomeworkModel {
    id = 0;
    name = '';
    startedAt = '';
    finishedAt = '';
    problems = [];

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const homework = pickCls(this, renameKeys(camelizeKeys(data), homeworkConvertMap));
        homework.problems = homework.problems && homework.problems.map(problem => new ProblemModel(problem));
        homework.startedAt = moment(homework.startedAt, moment.ISO_8601).format('YY/MM/DD hh:mm a')
        homework.finishedAt = moment(homework.finishedAt, moment.ISO_8601).format('YY/MM/DD hh:mm a')
        set(this, homework);
    }
}

const homeworkDAOConvertMap = {

};

export class HomeworkDAOModel {
    name = '';
    startedAt = '';
    finishedAt = '';
    problems = [];

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const homework = pickCls(this, renameKeys(data, homeworkConvertMap));
        homework.problems = homework.problems && homework.problems.map(problem => new ProblemModel(problem));
        homework.startedAt = moment(homework.startedAt, 'YY/MM/DD hh:mm a')
        homework.finishedAt = moment(homework.finishedAt, 'YY/MM/DD hh:mm a')
        set(this, snakelizeKeys(homework));
    }
}
