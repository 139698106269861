import React, {useEffect} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import List from "@material-ui/core/List";
import {alpha, makeStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root : {
        height: 'calc(100vh - 240px)',
        padding: theme.spacing(5),
        margin: theme.spacing(5),
        backgroundColor: '#ffffff',
        borderRadius: '80px',
        width: 'calc(100% - 80px)'
    },
    itemBox: {

    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha('#000000', 0.2)
        }
    }
}))

export const QuestionList = ({bizGroupId, bizClassId, homeworkId}) => {
    const { questionStore } = useStores();
    const classes = useStyles();

    const history = useHistory();

    useEffect(() => {
        questionStore.fetchAll({bizGroupId, bizClassId, page: 0, homeworkId})
    }, [homeworkId])

    const handleRedirect = (questionId) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/questions/${questionId}`)
    }

    return useObserver(() => {
        const { questions } = questionStore;

        return (
            <List className={classes.root}>
                {
                    questions.map(question => (
                    <Box className={classes.itemBox}>
                        <ListItem alignItems="flex-start" className={classes.listItem} onClick={()=>handleRedirect(question.id)}>
                            <ListItemAvatar>
                                <Avatar alt={question.username}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={question.title}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {question.username}
                                        </Typography>
                                        {` - ${question.problemName}`}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Box>))
                }
            </List>
        )
    })
}
