import React from "react";
import {useObserver} from "mobx-react-lite";
import {useStores} from "../../../../../common/hooks/UseStores";
import {Typography} from "@material-ui/core";

export const LimitCountIndicator = () => {
    const { groupUserStore, bizClassStore } = useStores();

    return useObserver(() => {
        const { bizClass } = bizClassStore;
        const { groupUserCount } = groupUserStore;

        return (
            <Typography>
                {`현재 ${bizClass.maxUsersCnt - groupUserCount}명의 유저를 더 추가할 수 있습니다.`}
            </Typography>
        )
    })
}
