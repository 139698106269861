import React, {useEffect} from "react";
import Box from "@material-ui/core/Box";
import {SideBar} from "./SideBar";
import {MainContainer} from "./templates/MainContainer";
import {makeStyles} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {SidebarTheme} from "../../styles/theme";
import {useObserver} from "mobx-react-lite";
import {useStores} from "../../common/hooks/UseStores";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {BizGroupsContainer} from "./templates/BizGroups/BizGroupsContainer";

const useStyles = makeStyles(theme => ({
    root : {
        display: 'flex',
    }
}))

const MainPage = ({bizGroupId, bizClassId}) => {
    const classes = useStyles();
    const { bizClassStore } = useStores();

    useEffect(() => {
        bizClassStore.fetch({bizGroupId, bizClassId});
    }, [])

    return useObserver(() => {
        const {bizClass} = bizClassStore;

        if(!bizClass)
            return null;

        return (
            <Box className={classes.root}>
                <ThemeProvider theme={SidebarTheme}>
                    <SideBar bizGroupId={bizGroupId} bizClassId={bizClassId} />
                </ThemeProvider>
                <MainContainer bizClassId={bizClassId} bizGroupId={bizGroupId} />
            </Box>
        )
    })
}

const BizClassWithRouter = ({bizGroupId}) => {
    const match = useRouteMatch();

    const { bizGroupStore } = useStores();

    useEffect(() => {
        bizGroupStore.fetch({bizGroupId});
    }, [])

    return useObserver(() => {
        const { bizGroup } = bizGroupStore;
        
        if(!bizGroup)
            return null;

        return (
            <Switch>
                <Route
                    exact
                    path={`${match.url}`}
                    render={() => <Redirect to={`${match.url}/${bizGroup.classes[0].id}`} />}
                />
                <Route
                    path={`${match.url}/:bizClassId`}
                    render={({match: _match}) =>
                        <MainPage
                            bizGroupId={bizGroupId}
                            bizClassId={_match.params.bizClassId}
                        />}
                />
                <Redirect to={"/not-found"} />
            </Switch>
        )
    })
}

export const MainPageWithRouter = () => {
    const match = useRouteMatch();
    
    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                render={() => <BizGroupsContainer />}
            />
            <Route
                path={`${match.url}/:bizGroupId/biz-classes`}
                render={({match: _match}) => <BizClassWithRouter bizGroupId={_match.params.bizGroupId} />}
            />
            <Redirect to={"/not-found"} />
        </Switch>
    )
};


