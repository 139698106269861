import React, {useState} from "react";
import {makeStyles, TableBody, TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
    }
}))

export const SimpleTable = ({items}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={`${item.key}`}>
                                <TableCell>{item.key}</TableCell>
                                <TableCell align='right'>{item.value}</TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export const SimpleEditTable = ({items, inputs, submitRef, handleChange, handleSubmit}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {items.map(item => (
                            <TableRow key={`${item.key}`}>
                                <TableCell>{item.key}</TableCell>
                                <TableCell align='right' style={{display: 'flex'}}>{
                                    typeof item.value !== 'object' ?
                                        <TextField
                                            value={inputs[item.key]}
                                            name={item.key}
                                            onChange={handleChange}
                                        /> :
                                        item.value
                                }</TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={()=>handleSubmit(inputs)} style={{display: "none"}} ref={submitRef}>Submit</Button>
        </Box>
    )
}
