import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useObserver} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import {useStores} from "../../../../../../common/hooks/UseStores";
import {LoadingOverlay} from "../../../../../../common/components/LoadingOverlayMUI";
import {ReplyForm} from "./ReplyForm";
import {ReplyList} from "./ReplyList";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    replyForm: {
        marginTop: theme.spacing(1)
    },
    replyList: {
        marginTop: theme.spacing(1)
    },
    loadingOverlay: {
        display: "flex",
        flexDirection: 'column'
    },
}))

export const ReplyContainer = ({comment, handleIsReplyFormOpen, questionId, isOpen, isReplyFormOpen}) => {
    const classes = useStyles();
    const [replies, setReplies] = useState([]);
    const [loading, setLoading] = useState(false);

    const { replyStore, commentStore } = useStores();

    useEffect(() => {
        if(!isOpen)
            return;

        setLoading(true);

        replyStore.fetchAll({questionId, commentId: comment.id}).then(data => {
            setReplies(data);
            setLoading(false);
        })
    }, [isOpen])

    const onAdd = (newReply) => {
        commentStore.addReplyNum(comment.id);
        setReplies([...replies, newReply]);
        handleIsReplyFormOpen();
    }

    return useObserver(() => {
        return (
            <Box className={classes.root}>
                <LoadingOverlay
                    active={loading}
                    className={classes.loadingOverlay}
                >
                    {isReplyFormOpen ?
                        <Box className={classes.replyForm}>
                            <ReplyForm comment={comment} questionId={questionId} onAdd={onAdd}
                                       handleIsReplyFormOpen={handleIsReplyFormOpen}/>
                        </Box> : null
                    }
                    {isOpen ?
                        <Box className={classes.replyList}>
                            <ReplyList questionId={questionId} replies={replies} comment={comment}/>
                        </Box> : null
                    }
                </LoadingOverlay>
            </Box>
        )
    })
}
