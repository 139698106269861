import {GET, PATCH, unauthorizedGET, unauthorizedPOST} from "../../../../../utils/api";

class AuthRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    login({...body}) {
        return unauthorizedPOST('/api-token-auth/', body);
    }

    verifyToken() {
        return GET('/api-token-verify/');
    }

    activate({uidb64, token}) {
        return unauthorizedGET(`/activate/${uidb64}/${token}/`);
    }

    updateProfile({profileId, body}) {
        return PATCH(`/user-profiles/${profileId}/`, body);
    }

}

// 싱글톤으로 리턴
export default new AuthRepository();
