import {flow, makeAutoObservable} from "mobx";
import CuratedProblemRepository from "../repository/CuratedProblemRepository";
import {ConceptModel, MissionModel} from "../model/CuratedProblemModel";
import {getItem, setItem} from "../../../../../../../utils/localStorage";

const LocalStorageKey = "selectedGroup";

export default class CuratedProblemStore {
    missions = [];
    selectedMission = getItem(LocalStorageKey) || {id:4, name: "Novice Low", copy: "프로그래밍 기초"};
    concepts = [];
    conceptsMap = new Map();

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetchMission: flow,
        }, {autoBind: true});
    }

    onSelect = (selectedMission) => {
        this.selectedMission = selectedMission;
        setItem(LocalStorageKey, selectedMission);
    };

    *fetchAll() {
        if(!this.missions.length) {
            const response = yield CuratedProblemRepository.fetchAll();
            this.missions.replace(
                response.data.map((mission) => new MissionModel(mission))
            );

            const compare = (a, b) => {
                if (a.order < b.order) return -1;
                return 1;
            };
            this.missions.sort(compare);
        }

        return this.missions;
    }

    *fetchMission({missionOrder, missionId}) {
        if(!this.conceptsMap.has(missionId)) {
            const response = yield CuratedProblemRepository.fetchModel(missionId);
            const concepts = response.data.map((concept, i) => new ConceptModel({...concept, conceptOrder:i+1, missionOrder:missionOrder}));
            this.concepts.replace(concepts);
            this.conceptsMap.set(missionId, concepts)
        }
        else {
            this.concepts = this.conceptsMap.get(missionId).slice();
        }
    }
}
