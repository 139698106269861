import {flow, makeAutoObservable} from 'mobx';
import {GraphDataModel} from "../model/GraphDataModel";
import GraphDataRepository from "../repository/GraphDataRepository";


export default class GraphDataStore {
    graphDatas = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId, bizClassId, homeworkId}) {
        const response = yield GraphDataRepository.findAll({bizGroupId, bizClassId, homeworkId});
        this.graphDatas.replace(response.data.map(graphData => new GraphDataModel(graphData)));
    }
}
