import React, { Component } from 'react'
import {Box, makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

export const LoadingOverlay = ({active, text, className, spinnerSize, children}) => {
    const useStyles = makeStyles(theme => ({
        wrapper: {
            position: 'relative',
            height: '100%',
            width: '100%',
        },
        overlay : {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '0px',
            left: '0px',
            display: 'flex',
            textAlign: 'center',
            fontSize: '1.2em',
            color: '#FFF',
            background: 'rgba(0, 0, 0, 0.7)',
            zIndex: props => props.active ? 800 : -1,
            transition: `opacity 500ms ease-in`,
            opacity: props => props.active ? 1 : 0
        },
        content: {
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        spinner: {
            color: '#FFF',
            marginBottom: props => props.text ? 10 : 0,
        }
    }))

    const classes = useStyles({active, text})

    return (
        <Box className={clsx(className, classes.wrapper)}>
            {active ? <Box
                data-testid='overlay'
                className={classes.overlay}
            >
                <Box className={classes.content}>
                    <CircularProgress
                        className={classes.spinner}
                        size={spinnerSize}
                        thickness={2}
                    />
                    {text}
                </Box>
            </Box> : null}
            {children}
        </Box>
    )
}

LoadingOverlay.defaultProps = {
    spinnerSize: 50,
}
