import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    dot: {
        color: "#62809E",
        fontSize: (props) => props.fontSize,
        marginRight: 2,
    },
    unselDot: {
        opacity: 0.5,
    },
}));

export function LevelIndicator({ num, max, fontSize }) {
    const classes = useStyles({ fontSize });

    return (
        <Box className={classes.root}>
            {new Array(max).fill(1).map((item, i) => (
                <FiberManualRecordIcon
                    key={`dot-${i}`}
                    className={clsx(classes.dot, i >= num && classes.unselDot)}
                />
            ))}
        </Box>
    );
}
