import React, {useEffect, useState} from "react";
import {CustomTransferList} from "../../../../../common/components/CustomTransferList";


export const UsersTemplate = (props) => {
    const {users, groupUsers, userCount, groupUserCount, handlePageChange, loading, handleUpdate, handleSearch} = props;

    const columns = [
        {field: 'username', headerName: 'Username', width: 200, sortable: false, filterable: false},
        {field: 'email', headerName: 'E-mail', width: 200, sortable: false, filterable: false},
        {field: 'phoneNumber', headerName: 'Phone Number', width: 200, sortable: false, filterable: false},
    ]

    return (
        <CustomTransferList
            left={users}
            right={groupUsers}
            columns={columns}
            leftCount={userCount}
            rightCount={groupUserCount}
            handlePageChange={handlePageChange}
            loading={loading}
            handleUpdate={handleUpdate}
            handleSearch={handleSearch}
        />
    )
}
