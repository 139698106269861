import React, {cloneElement} from "react";
import {DragDropContext} from "react-beautiful-dnd";
import {Droppable} from "react-beautiful-dnd";
import {Draggable} from "react-beautiful-dnd";
import {makeId} from "../../utils/random";
import TextField from "@material-ui/core/TextField";

export const SimpleVerticalDnD= ({items, grid, handleDnD, dnDItem}) => {
    const DnDItem = dnDItem;

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        display: 'flex',
        flexDirection: 'row',

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "#ffffff",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: '700px'
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        handleDnD(newItems);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        key={`${makeId(10)}`}
                    >
                        {items.map((item, index) => (
                            <Draggable key={`${makeId(10)}-${item.id}`} draggableId={`item-${item.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        key={`inner-${item.id}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <DnDItem key={`box-${item.name}`} item={item} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

SimpleVerticalDnD.defaultProps = {
    grid : 8
}
