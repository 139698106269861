import React from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {Reply} from "./Reply";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    }
}))

export const ReplyList = ({replies, comment, questionId}) => {
    const classes = useStyles();

    if(!replies.length)
        return null;

    return (
        <Box className={classes.root}>
            {
                replies.map(item => (
                    <Reply reply={item} comment={comment} questionId={questionId} key={item.id} />
                ))
            }
        </Box>
    )
}
