import {flow, makeAutoObservable} from 'mobx';
import {ReplyModel} from "../model/ReplyModel";
import ReplyRepository from "../repository/ReplyRepository";


export default class ReplyStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            addReply: flow,
            updateReply: flow,
        }, {autoBind: true});
    }

    *fetchAll({questionId, commentId}) {
        const response = yield ReplyRepository.findAll({questionId, commentId});
        return response.data.map(comment => new ReplyModel(comment));
    }

    *addReply({questionId, commentId, body}) {
        const response = yield ReplyRepository.addReply({questionId, commentId, body});
        return new ReplyModel(response.data);
    }

    *updateReply({questionId, commentId, replyId, body}) {
        const response = yield ReplyRepository.updateReply({questionId, body, commentId, replyId});
        return new ReplyModel(response.data);
    }
}
