import React from "react";
import {Divider, makeStyles, Typography} from "@material-ui/core";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from '@material-ui/icons/Person';
import blue from "@material-ui/core/colors/blue";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dialogPaper: {
        maxWidth: 750,
        width: 750
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    boxHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 3)
    },
    userBox: {
        width: 250
    },
    title: {
        fontSize: theme.typography.h3.fontSize
    },
    userList: {
        height: 270,
        overflow: 'scroll'
    }
}))

const UserBoxes = ({title, users}) => {
    const classes = useStyles();

    return (
        <Box className={classes.userBox}>
            <Box className={classes.boxHeader}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.title}>
                    {users.length}
                </Typography>
            </Box>
            <List className={classes.userList}>
                {
                    users.map(user => (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={user.username} />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    )
}

export const DashGraphTemplate = ({data, handleClick, handleClose, popupOpen, activeLabel}) => {
    const classes = useStyles();

    const selectedData = data.find(graphData => graphData.problemName === activeLabel);

    return (
        <Box className={classes.root}>
            <ResponsiveContainer width='100%' height="100%">
                <BarChart data={data} onClick={handleClick}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"problemName"} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='passedNum' fill='#7bb7ec'/>
                    <Bar dataKey='inProgressNum' fill='#ed6d68'/>
                    <Bar dataKey='untriedNum' fill='#c4c4c4'/>
                </BarChart>
            </ResponsiveContainer>
            <Dialog
                open={popupOpen}
                onClose={handleClose}
                classes={{
                    paper: classes.dialogPaper
                }}
            >
                <DialogTitle>
                    {activeLabel}
                </DialogTitle>
                <DialogContent dividers className={classes.content}>
                {
                    selectedData && Object.keys(selectedData).filter(key => key.includes("Users")).map(key => (
                        <UserBoxes
                            title={key}
                            users={selectedData[key]}
                        />
                    ))
                }
                </DialogContent>
            </Dialog>
        </Box>
    )
}
