import {GET} from "../../../../utils/api";

class BizClassRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId}) {
        return GET(`/biz-groups/${bizGroupId}/`);
    }

    find({bizGroupId, bizClassId}) {
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/`);
    }
}

// 싱글톤으로 리턴
export default new BizClassRepository();
