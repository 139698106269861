import {makeAutoObservable, set} from "mobx";
import {pickCls} from "../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../utils/camelize";

const userConvertMap = {
};

export class UserModel {
    username = '';
    id = 0;
    paid = [];

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const user = pickCls(this, renameKeys(camelizeKeys(data), userConvertMap));
        set(this, user);
    }
}
