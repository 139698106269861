import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../../utils/camelize";
import {snakelizeKeys} from "../../../../../../utils/snakelize";
import moment from "moment";

const questionConvertMap = {
};

export class QuestionModel {
    id = 0;
    problemName = '';
    problemAlias = '';
    title = '';
    description = '';
    username = '';
    createdAt= '';
    homeworkName = '';
    resolved = false;
    isAnonymous = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const question = pickCls(this, renameKeys(camelizeKeys(data), questionConvertMap));
        question.createdAt = moment(question.createdAt, moment.ISO_8601).format('YY/MM/DD hh:mm:ss a')
        set(this, question);
    }
}

const questionDAOConvertMap = {

};

export class QuestionDAOModel {
    resolved = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const homework = pickCls(this, renameKeys(data, questionDAOConvertMap));
        set(this, snakelizeKeys(homework));
    }
}
