import React from "react";
import {useObserver} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {HomeworkView} from "./HomeworkView";
import {HomeworkEdit} from "./HomeworkEdit";
import AlertDialog from "../../../../../common/components/dialog/AlertDialog";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100vh',
        padding: theme.spacing(10),
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
    }
}))

export const HomeworkTemplate = (props) => {
    const {
        homework,
        redirectToHomeworks,
        deleteAndRedirect,
        handleDeleteOpen,
        handleClose,
        handleEdit,
        handleCancel,
        editing,
        handleUpdate,
        deleteOpen,
        oldVersion,
        handleToggleVersion,
        checkedProblems
    } = props;

    const classes = useStyles();

    return useObserver(() => {
        return (
            <Box className={classes.root}>
                <Box className={classes.top}>
                    <Button onClick={redirectToHomeworks}>
                        &lt; 숙제 화면으로 돌아가기
                    </Button>
                </Box>
                {!editing ?
                    <HomeworkView handleEdit={handleEdit} homework={homework} handleDeleteOpen={handleDeleteOpen} oldVersion={oldVersion} handleToggleVersion={handleToggleVersion} checkedProblems={checkedProblems}/>:
                    <HomeworkEdit homework={homework} handleCancel={handleCancel} handleUpdate={handleUpdate} oldVersion={oldVersion} handleToggleVersion={handleToggleVersion} checkedProblems={checkedProblems}/>
                }
                <AlertDialog
                    open={deleteOpen}
                    title={'정말로 숙제를 삭제하시겠습니까?'}
                    description={'삭제한 숙제는 다시 복원되지 않습니다.'}
                    handleConfirm={deleteAndRedirect}
                    handleClose={handleClose}
                />
            </Box>
        )
    })
}
