import React, {useEffect, useState} from "react";
import {QuestionsTemplate} from "./QuestionsTemplate";
import {useObserver} from "mobx-react-lite";
import {useStores} from "../../../../../common/hooks/UseStores";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Question} from "./Question";

export const QuestionsContainer = ({bizGroupId, bizClassId}) => {
    const {questionStore} = useStores();
    const [ loading, setLoading ] = useState(false);
    const [ setting, setSetting ] = useState({});
    const [ page, setPage ] = useState(0);
    const match = useRouteMatch();

    useEffect(() => {
        setLoading(true);
        questionStore.fetchAll({bizGroupId, bizClassId, page, homeworkId: 0, setting}).then(()=>setLoading(false));
    }, [bizGroupId, bizClassId])

    const handleSettingChange = (key, value) => {
        const newSetting = {
            ...setting,
            [key]: value
        };

        setLoading(true);

        setSetting(newSetting);
        questionStore.fetchAll({bizGroupId, bizClassId, page, homeworkId: 0, setting: newSetting}).then(()=>setLoading(false));
    }

    return useObserver(() => {
        const {questions, count} = questionStore;

        return (
            <Switch>
                <Route exact path={`${match.url}/`} render={()=> <QuestionsTemplate
                    bizGroupId={bizGroupId}
                    bizClassId={bizClassId}
                    questions={[...questions]}
                    count={count}
                    loading={loading}
                    handleSettingChange={handleSettingChange}
                />} />
                <Route path={`${match.url}/:questionId`} render={({match})=> <Question
                    bizGroupId={bizGroupId}
                    bizClassId={bizClassId}
                    questionId={match.params.questionId}
                />} />
            </Switch>
        )
    })
}
