import {GET, PATCH} from "../../../../../../utils/api";
import {makeQueryString} from "../../../../../../utils/makeQueryString";

class QuestionRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, page, homeworkId, setting}) {
        const queryString = makeQueryString(setting);
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/discussions/?page=${page+1}${queryString}`);
    }

    find({questionId}) {
        return GET(`/discussions/${questionId}/`)
    }

    updateQuestion({questionId, body}) {
        return PATCH(`/discussions/${questionId}/`, body)
    }
}

// 싱글톤으로 리턴
export default new QuestionRepository();
