import {makeAutoObservable, set} from "mobx";
import {pickCls} from "../../../../utils/immutableLodash";
import {renameKeys} from "../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../utils/camelize";
import {BizClassModel} from "../../bizClass/model/BizClassModel";


const bizGroupConvertMap = {

}

export class BizGroupModel {
    id = 0;
    name = '';
    classes = [];

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const bizGroup = pickCls(this, renameKeys(camelizeKeys(data), bizGroupConvertMap));
        bizGroup.classes = bizGroup.classes.map(bizClass => new BizClassModel(bizClass));
        set(this, bizGroup);
    }
}
