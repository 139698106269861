import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import { ToggleButton } from '@material-ui/lab';
import Drawer from "@material-ui/core/Drawer";
import {Dashboard, GroupAdd} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Box from "@material-ui/core/Box";
import {useObserver} from "mobx-react-lite";
import {useStores} from "../../common/hooks/UseStores";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SettingsIcon from "@material-ui/icons/Settings";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {snackActions} from "../../utils/SnackbarUtilsConfigurator";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        width: 56,
    },
    sidenav: {
        height: "100vh",
        width: 56,
        position: "fixed",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        borderRadius: 0,
        border: 'none',
        width: '100%'
    },
    tooltip: {
        fontSize: theme.typography.body1.fontSize
    },
    upperItems: {
    },
    bizClassName: {
        writingMode: 'vertical-rl',
        textOrientation: 'upright',
        fontSize: theme.typography.h2.fontSize,
    },
    menuButton: {
        padding: 0,
        minWidth: 0,
        margin: '20px auto'
    }
}))

export const SideBar = ({bizGroupId, bizClassId}) => {
    const [activeKey, setActiveKey] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingAnchorEl, setSettingAnchorEl] = useState(null);

    const classes = useStyles();

    const { bizGroupStore, bizClassStore, authStore } = useStores();

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setActiveKey(location.pathname.split('/')[5]);
    }, [location])

    const items = [
        {name: 'dashboards', icon: <Dashboard />, text: '대시보드', place: 'upper'},
        {name: 'users', icon: <GroupAdd />, text: '유저 등록', place: 'upper'},
        {name: 'homeworks', icon: <LibraryBooksIcon />, text: '숙제 등록/편집', place: 'upper'},
        {name: 'questions', icon: <QuestionAnswerIcon />, text: '질문 페이지', place: 'upper'},
        {name: 'submission', icon: <FormatListBulletedIcon />, text: '제출 목록', place: 'upper'},
    ]

    const redirectToItem = (item) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/${item.name}`);
    }

    const redirectToGroupChoose = () => {
        history.push(`/biz-groups/`);
    }

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const settingOpen = (e) => {
        setSettingAnchorEl(e.currentTarget)
    }

    const settingClose = () => {
        setSettingAnchorEl(null);
    }

    const logout = () => {
        const { logout } = authStore;
        settingClose();
        snackActions.info("로그아웃 되었습니다.");
        logout();
    };

    const handleChange = (bizClass) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClass.id}/${activeKey}`)
        window.location.reload();
        handleClose();
    }

    const toNavItems = items => items.map(item => (
        <Tooltip classes={{tooltip: classes.tooltip}} key={`item-${item.name}`} title={item.text} placement="right">
            <ToggleButton
                value={item.name}
                className={classes.button}
                selected={activeKey === item.name}
                onClick={()=>redirectToItem(item)}
            >
                {item.icon}
            </ToggleButton>
        </Tooltip>
    ));

    const navItems = toNavItems(items);

    return useObserver(() => {
        const { bizGroup } = bizGroupStore;
        const { bizClass } = bizClassStore;

        if(!(bizGroup && bizClass)) {
            return null;
        }

        return (
        <Drawer
            classes={{
                root: classes.root,
                paper: classes.sidenav
            }}
            anchor='left'
            variant='permanent'
        >
            <Box className={classes.upperItems}>
                {navItems}
            </Box>
            <Box>
                <Tooltip classes={{tooltip: classes.tooltip}} key={`item-classChange`} title='반 변경' placement="right">
                    <ToggleButton
                        value={'classChange'}
                        className={classes.button}
                        selected={activeKey === 'classChange'}
                        onClick={handleOpen}
                    >
                        <ArrowForwardIosIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip classes={{tooltip: classes.tooltip}} key={`item-settings`} title='설정' placement="right">
                    <ToggleButton
                        value={'settings'}
                        className={classes.button}
                        selected={activeKey === 'settings'}
                        onClick={settingOpen}
                    >
                        <SettingsIcon />
                    </ToggleButton>
                </Tooltip>
                <Menu
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                >
                    {bizGroup.classes.map(bizClass => (
                    <MenuItem onClick={() => handleChange(bizClass)}>
                        {bizClass.name}
                    </MenuItem>))}
                </Menu>
                <Menu
                    onClose={settingClose}
                    anchorEl={settingAnchorEl}
                    keepMounted
                    open={Boolean(settingAnchorEl)}
                >
                    <MenuItem onClick={redirectToGroupChoose} >
                        그룹 선택
                    </MenuItem>
                    <MenuItem onClick={logout} >
                        로그아웃
                    </MenuItem>

                </Menu>
            </Box>
        </Drawer>)
    })
}
