import React, {useState} from "react";
import {useObserver} from "mobx-react-lite";
import {useEffect} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {HomeworksTemplate} from "./HomeworksTemplate";
import {HomeworkDAOModel} from "./modules/model/HomeworkModel";
import {snackActions} from "../../../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../../../utils/errorHandler";
import {updateOrder} from "../../../../../utils/immutableLodash";

export const HomeworksContainer = ({bizGroupId, bizClassId}) => {
    const { homeworkStore } = useStores();
    const { count } = homeworkStore;

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [excelEditing, setExcelEditing] = useState(false);
    const [emailEditing, setEmailEditing] = useState(false);
    const [oldVersion, setOldVersion] = useState(false);

    useEffect(() => {
        setLoading(true);
        homeworkStore.fetchAll({bizGroupId, bizClassId, page: 0}).then(()=>setLoading(false));
    }, [count, bizGroupId, bizClassId])

    const handlePageChange = page => {
        setLoading(true);
        homeworkStore.fetchAll({bizGroupId, bizClassId, page}).then(()=>setLoading(false));
    }

    const handleEdit = () => {
        setEditing(true);
    }

    const downloadExcel = ({reportType, percentOfO, certificateMaxX}) => {
        homeworkStore.downloadExcel({bizClassId, reportType, percentOfO, certificateMaxX}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reportType+'.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const sendCertEmail = ({certificateMaxX, certNum}) => {
        homeworkStore.sendCertEmail({bizClassId, certificateMaxX, certNum}).then(
            () => {
                snackActions.success("정상적으로 메일이 발송되었습니다.")
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );

        setEmailEditing(false);
    }

    const testCertEmail = () => {
        homeworkStore.testCertEmail({bizClassId}).then(
            (response) => {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );
    }

    const countCertificate = ({certificateMaxX}) => {
        return homeworkStore.countCertificate({bizClassId, certificateMaxX});
    }

    const excelEdit = () => {
        setExcelEditing(true);
    }

    const excelCancel = () => {
        setExcelEditing(false);
    }

    const emailEdit = () => {
        setEmailEditing(true);
    }

    const emailCancel = () => {
        setEmailEditing(false);
    }

    const handleCancel = () => {
        setEditing(false);
    }

    const handleToggleVersion = () => {
        if(oldVersion) setOldVersion(false);
        else setOldVersion(true);
    }

    const handleUpdate = (inputs) => {
        const newInputs = {...inputs, problems: updateOrder(inputs.problems)};
        const newHomework = new HomeworkDAOModel(newInputs);
        if(inputs.id) {
            homeworkStore.updateHomework({bizGroupId, bizClassId, homeworkId: inputs.id, body: newHomework}).then(
                () => {
                    snackActions.success("정상적으로 수정되었습니다.");
                    setEditing(false);
                }
            ).catch(
                (error) => {
                    errorToast(error);
                }
            );
        } else {
            homeworkStore.addHomework({bizGroupId, bizClassId, body: newHomework}).then(
                () => {
                    snackActions.success("정상적으로 추가되었습니다.");
                    setEditing(false);
                }
            ).catch(
                (error) => {
                    errorToast(error);
                }
            );
        }
    }

    const handleDelete = (homeworkId) => {
        homeworkStore.deleteHomework({bizGroupId, bizClassId, homeworkId}).then(
            () => {
                snackActions.success("정상적으로 삭제되었습니다.")
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );
    }

    return useObserver(() => {
        const {homeworks, count, checkedProblems} = homeworkStore;

        return (
            <HomeworksTemplate
                bizGroupId={bizGroupId}
                bizClassId={bizClassId}
                homeworks={[...homeworks]}
                handlePageChange={handlePageChange}
                loading={loading}
                handleUpdate={handleUpdate}
                editing={editing}
                excelEditing={excelEditing}
                emailEditing={emailEditing}
                downloadExcel={downloadExcel}
                sendCertEmail={sendCertEmail}
                testCertEmail={testCertEmail}
                countCertificate={countCertificate}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                excelEdit={excelEdit}
                excelCancel={excelCancel}
                emailEdit={emailEdit}
                emailCancel={emailCancel}
                count={count}
                handleDelete={handleDelete}
                oldVersion={oldVersion}
                handleToggleVersion={handleToggleVersion}
                checkedProblems={checkedProblems}
            />
        )
    })
}
