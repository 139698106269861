import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/styles";
import {useObserver} from "mobx-react-lite";
import Moment from "react-moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import {codeMirrorModeMap, SubmissionStatusType} from "../modules/model/SubmissionModel";
import {SubmissionStatusToColor} from "../modules/model/SubmissionModel";
import {downloadFileFromUrl} from "../../../../../utils/download";
import Editor from "@monaco-editor/react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from "@material-ui/core/IconButton";
import {snackActions} from "../../../../../utils/SnackbarUtilsConfigurator";
import {useStores} from "../../../../../common/hooks/UseStores";

const useStyles = makeStyles( theme => ({
    editor: {
        width: "50vw",
        height: "80vh"
    },
    content: {
        display: "flex",
        flexDirection: "column"
    },
    summaryBox: {
        border: "1px solid #e0e0e0",
        marginTop: theme.spacing(2)
    },
    resultDetail: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent:"space-between",
    },
    smallContentLeftBox: {
        display: "flex",
        flexDirection: "column",
    },
    smallContentRightBox: {
        display: "flex",
        flexDirection: "column",
        textAlign: "end",
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    stderrBox: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        whiteSpace: "pre-line",
        padding: theme.spacing(1),
        backgroundColor: "#f2d2d2",
        color: "#a94442"
    },
    language: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    code: {
        height: "40vh",
        marginTop: theme.spacing(1.5),
        overflow: "auto",
    },
    monacoEditor: {
        border: "1px solid #000000",
    },
    downloadButtons: {
        display: "flex",
    },
    downloadButton: {
        marginRight: theme.spacing(1)
    },
    user: {

    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    message: {

    },
}));

const DownloadButton = ({name, ...props}) => {
    return (
        <Button
            variant="contained"
            size="small"
            color="default"
            {...props}
        >
            {name}
        </Button>
    )
};

export const CodeDialogTemplate = ({submission, open, handleClose}) => {
    const classes = useStyles();

    const {rootStore} = useStores();

    const downloadFailedTestcase = ({problemId, submissionId, resultId, isInputFile}) => {
        const {getFailedTestcaseUrl} = rootStore.submissionStore;
        getFailedTestcaseUrl({problemId, submissionId, resultId, isInputFile}).then((url) => {
            downloadFileFromUrl(url);
        });
    };

    return useObserver(() => {
        if(!submission)
            return null;

        const {createdAt, code, language, problemName, username} = submission;
        const {runtime, memory, status, compileStderr, executeStderr, failedIndex} = submission.result;

        const options = {
            lineNumbers: true,
            mode: codeMirrorModeMap[language],
            tabSize: 2,
            indentWithTabs: true,
            keyMap: 'sublime',
            readOnly: true,
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="code-dialog" open={open} maxWidth={false} scroll={"paper"}
                    PaperProps={{className: classes.editor}}>
                <DialogTitle disableTypography className={classes.title}>
                    <Typography className={classes.problem}>
                        문제: {problemName}
                    </Typography>
                    <Typography className={classes.user}>
                        유저: {username}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Typography variant="h5">
                        상세 결과
                    </Typography>
                    <Box className={classes.summaryBox}>
                        <Box className={classes.resultDetail}>
                            <Box className={classes.smallContentLeftBox}>
                                <Box>Runtime: <strong>{runtime >= 0 ? `${runtime}ms` : "N/A"}</strong></Box>
                                <Box>Memory: <strong>{memory >= 0 ? `${memory}MB` : "N/A"}</strong></Box>
                            </Box>
                            <Box className={classes.smallContentRightBox}>
                                <Box>Status: <Box component="span" color={SubmissionStatusToColor[status]}>
                                    <strong>{status}</strong>
                                </Box></Box>
                                <Box>Submitted: <strong><Moment format="YYYY년 MM월 DD일 HH:mm:ss">
                                    {createdAt}
                                </Moment></strong></Box>
                            </Box>
                        </Box>
                        <Box className={classes.message}>
                            {status === SubmissionStatusType.CompileError &&
                            <Box className={classes.stderrBox}>
                                {compileStderr}
                            </Box>
                            }
                            {status === SubmissionStatusType.RuntimeError &&
                            <Box className={classes.stderrBox}>
                                {executeStderr}
                            </Box>
                            }
                            {(status === SubmissionStatusType.WrongAnswer || status === SubmissionStatusType.RuntimeError || status === SubmissionStatusType.TimeLimitExceed) &&
                            <Box className={classes.stderrBox}>
                                <Box>
                                    틀린 테스트 케이스 번호: <strong>{failedIndex}번</strong>
                                </Box>
                                <Box className={classes.downloadButtons}>
                                    <DownloadButton className={classes.downloadButton} name="Input 파일"
                                                    onClick={() => downloadFailedTestcase(
                                                        {
                                                            problemId: submission.problemId,
                                                            submissionId: submission.id,
                                                            resultId: submission.result.id,
                                                            isInputFile: true
                                                        }
                                                    )}
                                    />
                                    <DownloadButton className={classes.downloadButton} name="정답 Output 파일"
                                                    onClick={() => downloadFailedTestcase(
                                                        {
                                                            problemId: submission.problemId,
                                                            submissionId: submission.id,
                                                            resultId: submission.result.id,
                                                            isInputFile: false
                                                        }
                                                    )}
                                    />
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Box>
                    <Divider className={classes.divider}/>
                    <Box>
                        <Typography variant="h5">
                            제출한 코드
                        </Typography>
                        <Box className={classes.language}>
                            <Typography>
                                Language: {language}
                            </Typography>
                            <CopyToClipboard
                                text={code ? code : ""}
                                onCopy={() => snackActions.success('클립보드에 복사 되었습니다.')}>
                                <IconButton><FileCopyIcon fontSize='small'/></IconButton>
                            </CopyToClipboard>
                        </Box>
                        <Box className={classes.code}>
                            <Editor
                                language={codeMirrorModeMap[language]}
                                height={code ? code.split('\n').length * 24 : 0}
                                value={code ? code : ""}
                                className={classes.monacoEditor}
                                options={{
                                    readOnly: true,
                                    fontSize: 16,
                                    scrollBeyondLastLine: false,
                                    scrollbar: {
                                        alwaysConsumeMouseWheel: false,
                                        vertical: 'hidden',
                                    },
                                    minimap: {
                                        enabled: false,
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    })
};
