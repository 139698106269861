import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {useObserver} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles( theme => ({
    root: {
        margin: "auto"
    }
}));

export const Default = ({})  => {
    const classes = useStyles();
    return useObserver(() => (
        <Box
            display="flex"
            flexDirection="column"
            className={classes.root}
        >
            <video
                autoPlay
                loop
            >
                <source src="https://s1.sentry-cdn.com/_static/2fa279920dc41377d2871322561abdb4/sentry/dist/congrats-robots.c1aa5c.mp4" type="video/mp4"/>
            </video>
            <Box m={2}>
                <Typography variant="h4" color="textPrimary">
                    문제가 없어 Table을 불러올 수 없습니다.
                </Typography>
                <Typography variant="h5" color="textSecondary">
                    해당 과제에 문제를 추가해주세요.
                </Typography>
            </Box>
        </Box>
    ));
};
