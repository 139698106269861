import React, {useState} from "react";
import {DataGrid} from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import {IconButton, makeStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useHistory} from "react-router-dom";
import {HomeworkEdit} from "../Homework/HomeworkEdit";
import Button from "@material-ui/core/Button";
import {CustomPagination} from "../../../../../common/components/CustomPagination";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import {useObserver} from "mobx-react-lite";
import AlertDialog from "../../../../../common/components/dialog/AlertDialog";
import {ExcelEdit} from "./ExcelEditForm";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100vh',
        padding: theme.spacing(10),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%'
    },
    button: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    table: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    addButton: {
        backgroundColor: '#90e0a0',
        marginBottom: theme.spacing(2.5)
    },
    getAppButton: {
        backgroundColor: 'lightblue',
        marginBottom: theme.spacing(2.5),
        marginRight: theme.spacing(1.5)
    },
    EmailButton: {
        backgroundColor: '#d5d5bc',
        marginBottom: theme.spacing(2.5),
        marginRight: theme.spacing(1.5)
    },
    dialogPaper: {
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
    },
    dialogPaperExcel: {
        width: '60vw',
        maxWidth: '60vw',
        height: '60vh',
    },
    dataGrid: {
        '& .MuiDataGrid-columnHeaderWrapper' : {
            backgroundColor: 'pink'
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid ##f0f0f0'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid #f0f0f0`,
        },
        '& .MuiDataGrid-row': {
            backgroundColor: '#ffffff',
        },
        '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'pink'
        }
    }
}))

export const HomeworksTemplate = (props) => {
    const { homeworks,
        handlePageChange,
        handleDelete,
        loading,
        editing,
        excelEditing,
        emailEditing,
        downloadExcel,
        sendCertEmail,
        testCertEmail,
        countCertificate,
        handleEdit,
        handleCancel,
        excelEdit,
        excelCancel,
        emailEdit,
        emailCancel,
        count,
        handleUpdate,
        bizGroupId,
        bizClassId,
        oldVersion,
        handleToggleVersion,
        checkedProblems
    } = props;

    const classes = useStyles();
    const history = useHistory();

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [api, setApi] = useState(null);
    const [homeworkId, setHomeworkId] = useState(null);

    const handleClick = (params) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${params.row.id}`)
    }

    const childDelete = () => {
        const page = api.getState().pagination.page
        const rowIndex = api.getRowIndex(homeworkId)
        const rowsCount = api.getRowsCount()
        const pageSize = api.getState().pagination.pageSize

        const isFirstRow = (rowIndex === 0);
        const isLast = rowsCount === rowIndex + 1 + page * pageSize;

        handleDelete(homeworkId);

        if(isFirstRow && isLast && page) {
            api.setPage(page - 1);
        }

        setDeleteOpen(false);
    }

    const storeContext = (e, homeworkId, api) => {
        e.stopPropagation();

        setHomeworkId(homeworkId);
        setApi(api);

        setDeleteOpen(true);
    }

    const handleClose = () => {
        setDeleteOpen(false);
    }

    function getRowIndex(params) {
        return `${params.api.getRowIndex(params.id) + 1
        + params.api.getState().pagination.page * params.api.getState().pagination.pageSize}`;
    }

    const DeleteButton = ({homeworkId, api}) => {
        return (
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={(e) => storeContext(e, homeworkId, api)}
            >
                X
            </Button>
        )
    }

    const columns = [
        {field: 'number', headerName: '#', width: 50, sortable: false, editable: false,
            valueGetter: getRowIndex
        },
        {field: 'name', headerName: 'Name', width: 200, sortable: false, editable: false},
        {field: 'startedAt', headerName: 'Started_at', width: 200, sortable: false, editable: false,},
        {field: 'finishedAt', headerName: 'Finished_at', width: 200, sortable: false, editable: false},
        {field: 'problemCnt', headerName: 'Problems', width: 100, sortable: false, editable: false,
            valueGetter: (params) => `${(params.getValue(params.id, "problems").length)}`
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 150,
            renderCell: (params) => (
                <DeleteButton
                    homeworkId={params.id}
                    api={params.api}
                />
            ),
        },
    ]

    const mockHomework = {
        id: 0,
        name: "",
        startedAt: moment(),
        finishedAt: moment(),
        problems: []
    }

    return useObserver(() => (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.button}>
                    <IconButton className={classes.getAppButton} onClick={excelEdit}>
                        <GetAppIcon />
                    </IconButton>
                    <IconButton className={classes.addButton} onClick={handleEdit}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <Box className={classes.table}>
                    <DataGrid
                        disableColumnMenu
                        pagination
                        pageSize={10}
                        className={classes.dataGrid}
                        columns={columns}
                        rows={homeworks}
                        rowCount={count}
                        autoHeight
                        paginationMode='server'
                        components={{
                            Pagination: CustomPagination
                        }}
                        onPageChange={handlePageChange}
                        loading={loading}
                        onRowClick={handleClick}
                    />
                </Box>
            </Box>
            <Dialog classes={{paper: classes.dialogPaper}} open={editing} onClose={handleCancel}>
                <HomeworkEdit homework={mockHomework} handleCancel={handleCancel} handleUpdate={handleUpdate}
                              oldVersion={oldVersion} checkedProblems={checkedProblems}
                              handleToggleVersion={handleToggleVersion}/>
            </Dialog>
            <Dialog classes={{paper: classes.dialogPaperExcel}} open={excelEditing} onClose={excelCancel}>
                <ExcelEdit excelCancel={excelCancel} downloadExcel={downloadExcel} sendCertEmail={sendCertEmail} testCertEmail={testCertEmail} emailEditing={emailEditing} emailEdit={emailEdit} emailCancel={emailCancel} countCertificate={countCertificate}/>
            </Dialog>
            <AlertDialog
                title={'정말로 숙제를 삭제하시겠습니까?'}
                description={'삭제한 숙제는 다시 복원되지 않습니다.'}
                handleConfirm={childDelete}
                handleClose={handleClose}
                open={deleteOpen}
            />
        </Box>
    ));
}
