import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {SnackbarProvider} from "notistack";
import {makeStyles} from "@material-ui/core";
import {SnackbarUtilsConfigurator} from "./utils/SnackbarUtilsConfigurator";
import {DefaultTheme} from "./styles/theme";
import {useStores} from "./common/hooks/UseStores";
import {BrowserRouter, Redirect, Route} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Switch} from "react-router-dom";
import {MainPageWithRouter} from "./pages/Main/MainPage";
import LoginPage from "./pages/Login/LoginPage";
import {Page404} from "./common/page/Page404";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

const useGlobalStyles = makeStyles(theme => ({
  "@global": {
    "html, body, #app": {
      width: "100%",
      height: "100%",
    }
  }
}))

const useStyles = makeStyles(theme => ({
  snackbarRoot: {
    '& #notistack-snackbar': {
        fontSize: 16,
    }
  }
}))

const Router = () => {
    const {authStore} = useStores();

    return (
        <BrowserRouter>
            <CssBaseline />
            <Switch>
                <Route exact path='/not-found' render={() => <Page404 />} />
                <Route
                    exact
                    path="/"
                    render={() => (authStore.isUserLoggedIn()) ? <Redirect to={"/biz-groups"} /> : <LoginPage />}
                />
                <Route
                    path="/biz-groups"
                    render={() => (authStore.isUserLoggedIn()) ? <MainPageWithRouter /> : <Redirect to={"/"} />}
                />
            </Switch>
        </BrowserRouter>
    )
}

const App = () => {
  useGlobalStyles();
  const classes = useStyles();

  return (
    <ThemeProvider theme={DefaultTheme}>
      <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={2000}
          classes={{
            root: classes.snackbarRoot
          }}
      >
       <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarUtilsConfigurator />
        <Router />
       </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
