import {set, makeAutoObservable} from "mobx";
import {camelizeKeys} from "../../../../../../../utils/camelize";
import {pickCls} from "../../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../../utils/renameKeys";

export const MissionNameType = {
    NoviceLow: "Novice Low",
    NoviceMid: "Novice Mid",
    NoviceHigh: "Novice High",
    IntermediateLow: "Intermediate Low",
    IntermediateMid: "Intermediate Mid",
    IntermediateHigh: "Intermediate High",
};

export const MissionNameTypeToExpectedMonth = {
    [MissionNameType.IntermediateMid]: 1,
    [MissionNameType.IntermediateHigh]: 4,
};

export const MissionNameTypeToColor = {
    [MissionNameType.NoviceLow]: "yellow",
    [MissionNameType.NoviceMid]: "red",
    [MissionNameType.NoviceHigh]: "purple",
    [MissionNameType.IntermediateLow]: "indigo",
    [MissionNameType.IntermediateMid]: "green",
    [MissionNameType.IntermediateHigh]: "blue",
};

export const MissionNameTypeToColorCode = {
    [MissionNameType.NoviceLow]: "#DEA61D",
    [MissionNameType.NoviceMid]: "#AE2B35",
    [MissionNameType.NoviceHigh]: "#763568",
    [MissionNameType.IntermediateLow]: "#264348",
    [MissionNameType.IntermediateMid]: "#3A5A33",
    [MissionNameType.IntermediateHigh]: "#114FAC",
};

export const MissionNameTypeToGradient = {
    [MissionNameType.NoviceLow]:
        "linear-gradient(180deg, #FFC433 0%, #DEA61D 100%)",
    [MissionNameType.NoviceMid]:
        "linear-gradient(180deg, #D24D57 0%, #AE2B35 100%)",
    [MissionNameType.NoviceHigh]:
        "linear-gradient(180deg, #964985 0%, #763568 100%)",
    [MissionNameType.IntermediateLow]:
        "linear-gradient(180deg, #5D7377 0%, #264348 100%)",
    [MissionNameType.IntermediateMid]:
        "linear-gradient(180deg, #6B9362 0%, #3A5A33 100%)",
    [MissionNameType.IntermediateHigh]:
        "linear-gradient(360deg, #114FAC 0%, #1A64D3 100%)",
};

export const MissionNameTypeToInfo = {
    [MissionNameType.NoviceLow]: "확실한 첫 단추",
    [MissionNameType.NoviceMid]: "생각을 코드로",
    [MissionNameType.NoviceHigh]: "개념을 탄탄히",
    [MissionNameType.IntermediateLow]: "실수 없는 코드 작성",
    [MissionNameType.IntermediateMid]: "정확하게 효율적으로",
    [MissionNameType.IntermediateHigh]: "빈틈 없는 실력",
};

export const MissionLevelType = {
    Novice: "Novice",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
};

export const MissionLevelColorSet = {
    Novice: "#FD7F57",
    Intermediate: "#42B273",
    Advanced: "#4EA6FF",
    Unused: "#B8B8B8",
};

export const MissionLevelGradientColorSet = {
    Novice: "linear-gradient(180deg, #FD8864 0%, #FED4C6 100%)",
    Intermediate: "linear-gradient(180deg, #42B273 0%, #BCE4CE 100%)",
    Advanced: "linear-gradient(180deg, #4EA6FF 0%, #C0E0FF 100%)",
    Unused: "#D7D7DC",
};

export const StudyUnitTreeModelType = {
    StudyUnit: "StudyUnit",
    Problem: "Problem",
};

export const ProblemStatusType = {
    Ready: "Ready",
    InProgress: "In Progress",
    Passed: "Passed",
};

export const ProblemType = {
    CodeBlock: "Code block",
    MultipleChoice: "Multiple choice",
    SequentialChoice: "Sequential choice",
};

export const ImportanceType = {
    ONE_MONTH: "1 Month",
    THREE_MONTHS: "3 Months",
    SIX_MONTHS: "6 Months",
    NINE_MONTHS: "9 Months",
    ONE_YEAR: "1 Year",
};

export class MissionModel {
    id = 0;
    name = '';
    order = 0;
    copy = '';
    description = '';
    isPublic = false;
    usable = false;
    totalProblemCount = 0;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const mission = pickCls(this, camelizeKeys(data));
        set(this, mission);
    }
}

const conceptConvertMap = {
    mission: "missionId",
};

export class ConceptModel {
    id = 0;
    name = '';
    description = '';
    level = 0;
    missionId = 0;
    isHidden = false;
    studyUnits = [];
    totalProblemCount = 0;
    isLast = false;
    nextConceptId = 0;
    conceptOrder = 0;
    missionOrder = 0;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const concept = pickCls(
            this,
            renameKeys(camelizeKeys(data), conceptConvertMap)
        );
        concept.studyUnits =
            concept.studyUnits &&
            concept.studyUnits.length &&
            concept.studyUnits.map((item, i) => new StudyUnitModel({...item, missionId:concept.missionId, conceptId:concept.id, studyUnitOrder:i+1, conceptOrder:concept.conceptOrder, missionOrder:concept.missionOrder}));
        set(this, concept);
    }
}

const studyUnitConvertMap = {
    curatedProblems: "problems",
};

export class StudyUnitModel {
    id = 0;
    name = '';
    description = '';
    problems = [];
    totalProblemCount = 0;
    testProblemCount = 0;
    introProblemCount = 0;
    missionId = 0;
    conceptId = 0;
    studyUnitOrder = 0;
    conceptOrder = 0;
    missionOrder = 0;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const studyUnit = pickCls(
            this,
            renameKeys(camelizeKeys(data), studyUnitConvertMap)
        );
        studyUnit.problems =
            studyUnit.problems &&
            studyUnit.problems.length &&
            studyUnit.problems.map(
                (problem, i) => new CuratedProblemModel({...problem, missionId:studyUnit.missionId, conceptId:studyUnit.conceptId, studyUnitId:studyUnit.id, problemOrder:i+1, conceptOrder:studyUnit.conceptOrder, studyUnitOrder:studyUnit.studyUnitOrder, missionOrder:studyUnit.missionOrder})
            );
        set(this, studyUnit);
    }
}

const curatedProblemConvertMap = {};

export class CuratedProblemModel {
    id = 0;
    name = '';
    alias = '';
    isIntro = false;
    isTest = false;
    level = 0;
    missionId = 0;
    conceptId = 0;
    studyUnitId = 0;
    problemOrder = 0;
    studyUnitOrder = 0;
    conceptOrder = 0;
    missionOrder = 0;

    nodeType = StudyUnitTreeModelType.Problem;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const newData = pickCls(
            this,
            renameKeys(camelizeKeys(data), curatedProblemConvertMap)
        );
        set(this, newData);
    }
}
