import {useGridSlotComponentProps} from "@material-ui/data-grid";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 52,
        alignItems: 'center'
    },
    pagination: {
        display: 'flex',
    },
    typo: {
        margin: '0 15px',
        fontSize: theme.typography.caption.fontSize
    }
}))

export const CustomPagination = (props) => {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();

    const selectionCount = props.selectionModel && props.selectionModel.length;

    return (
        <Box className={classes.footerContainer}>
            <Typography className={classes.typo}>
                {selectionCount ? `${selectionCount} ${(selectionCount > 1) ? 'rows' : 'row'} selected` : ''}
            </Typography>
            <Pagination
                className={classes.pagination}
                color="primary"
                count={state.pagination.pageCount}
                page={state.pagination.page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        </Box>
    );
}
