import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../../../utils/immutableLodash";
import {renameKeys} from "../../../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../../../utils/camelize";
import {snakelizeKeys} from "../../../../../../utils/snakelize";

const groupUserConvertMap = {
};

export class GroupUserModel {
    username = '';
    id = 0;
    email = '';
    phoneNumber = '';

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const user = pickCls(this, renameKeys(camelizeKeys(data), groupUserConvertMap));
        set(this, user);
    }
}

const groupUserDAOConvertMap = {

};

export class GroupUserDAOModel {
    username = '';
    phone_number = '';
    email = '';

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const groupUser = pickCls(this, renameKeys(snakelizeKeys(data), groupUserDAOConvertMap));
        set(this, groupUser);
    }
}
