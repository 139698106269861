import React from "react";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    root: {
        flexDirection: "column",
        backgroundColor: theme.palette.background.light,
        padding: theme.spacing(10),
        paddingTop: 0,
        borderRadius: 16,
        maxWidth: 400,
        margin: 'auto',
        alignItems: 'center',
    },
    card: {
        minWidth: 100,
        maxWidth: 200,
        minHeight: 100,
        maxHeight: 200,
        boxShadow: "none",
        margin: "auto",
        overflow: "visible"
    },
    textField: {
        marginTop: theme.spacing(2.5)
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(4)
    },
    loginButton: {
        fontSize: 16
    }
}))

export const LoginTemplate = ({inputs, onChange, onSubmit, onKeyPress}) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Card className={classes.card}>
                <CardMedia
                    component="img"
                    image={
                        "https://contents.codetree.ai/homepage/images/logos/Squarelogo_transparent.png"
                    }
                    title="Concept Icon"
                    className={classes.cardMedia}
                />
            </Card>
            <form noValidate onSubmit={onSubmit} onKeyPress={onKeyPress}>
                <TextField
                    id='username'
                    label='username'
                    value={inputs.username}
                    onChange={onChange}
                    autoFocus
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                />
                <TextField
                    id='password'
                    label='password'
                    type='password'
                    value={inputs.password}
                    onChange={onChange}
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                />
            </form>
            <Box className={classes.buttons}>
                <Button
                    className={classes.loginButton}
                    variant='contained'
                    color='primary'
                    onClick={onSubmit}
                    fullWidth
                >
                    Login
                </Button>
            </Box>
        </Paper>
    )
}
