import {GET} from "../../../../../../../utils/api";

class GraphDataRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, homeworkId}) {
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/graph-data/`);
    }
}

// 싱글톤으로 리턴
export default new GraphDataRepository();
