import React, {useEffect, useState} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {
    Accordion,
    AccordionSummary, Checkbox,
    Divider,
    makeStyles,
    Typography,
    withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import clsx from "clsx";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Button from "@material-ui/core/Button";
import {LevelIndicator} from "./LevelIndicator";
import {LevelType} from "../../../../../common/components/level/LevelType";
import {useObserver} from "mobx-react-lite";
import {ProblemModel} from "../../../../../common/modules/problem/model/ProblemModel";
import {autorun} from "mobx";
import {LoadingOverlay} from "../../../../../common/components/LoadingOverlayMUI";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '20px 0'
    },
    button: {
        margin: '0 10px'
    },
    rootMission: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 20px",
        [theme.breakpoints.down(600)]: {
            height: "calc(100vh - 48px)",
        },
    },
    paper: {
        width: 710,
        padding: "0 30px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 20px rgba(33, 33, 33, 0.05)",
        borderRadius: 8,
        [theme.breakpoints.down(750)]: {
            width: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
        },
    },
    header: {
        minHeight: 156,
        width: 710,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        backgroundColor: "#ffffff",
        marginTop: 80,
        boxShadow: "0px 0px 20px rgba(33, 33, 33, 0.05)",
        borderRadius: 8,
        padding: 30,
        [theme.breakpoints.down(750)]: {
            width: "100%",
            flexDirection: "column",
            minHeight: "fit-content",
            alignItems: "flex-start",
        },
    },
    headerTypo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignSelf: "flex-start",
    },
    title: {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: 700,
        letterSpacing: "-0.6px",
        lineHeight: "36px",
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    description: {
        marginTop: 12,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
        letterSpacing: "-0.2px",
        lineHeight: "24px",
        color: "#212121",
        wordBreak: "keep-all",
        whiteSpace: "pre-line",
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
        },
    },
    headerButton: {
        width: 255,
        borderRadius: "40px",
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 700,
        background: "#FFFFFF",
        padding: "10px 24px",
        marginTop: 20,
    },
    conceptList: {
        width: "100%",
    },
    studyUnitTypo: {
        marginLeft: 17,
        display: "flex",
        flexDirection: "column",
    },
    studyUnitTitle: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: "24px",
        fontWeight: 500,
        letterSpacing: "-0.6px",
        marginTop: 4,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.caption.fontSize,
            marginTop: 0,
        },
    },
    studyUnitDescription: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 400,
        color: "rgba(33, 33, 33, 0.6)",
        letterSpacing: "-0.2px",
        whiteSpace: "pre-line",
        wordBreak: "keep-all",
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.caption2.fontSize,
            lineHeight: "14.06px",
        },
    },
    studyUnitText: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 400,
        letterSpacing: "-0.6px",
        marginLeft: 20,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.caption.fontSize,
            textAlign: "left",
            marginLeft: 0,
        },
    },
    conceptName: {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 700,
        letterSpacing: "-0.6px",
        lineHeight: "25.04px",
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    conceptDescription: {
        marginTop: 4,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.2px",
        color: "rgba(33, 33, 33, 0.6)",
        marginLeft: 0,
        whiteSpace: "pre-line",
        wordBreak: "keep-all",
    },
    studyUnit: {
        width: "100%",
        boxShadow: "none",
    },
    problem: {
        width: "100%",
        justifyContent: "flex-start",
        paddingTop: 2.5,
        paddingLeft: 12,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 24,
        },
    },
    problemLabel: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    timelineContent: {
        padding: 0,
    },
    loadingOverlay: {
        minHeight: "150px",
    },
    problemTag: {
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 500,
        textTransform: "initial",
        width: 57,
        textAlign: "left",
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.body1.fontSize,
            textAlign: "left",
        },
    },
    accordionRoot: {
        boxShadow: "none",
        "&:before": {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down("xs")]: {
            boxShadow: "0px 0px 20px rgba(33, 33, 33, 0.05)",
            borderRadius: "8px",
        },
    },
    divider: {
        backgroundColor: "#E1E1E1",
        zIndex: 1000,
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    accordionSummary: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
        height: 96,
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            height: "fit-content",
            padding: "0 20px",
        },
    },
    accordionDetails: {
        margin: "0 48px 0 45px",
        padding: "8px 0 0 0",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "20px",
        },
    },
    accordionContent: {
        margin: 0,
        display: "flex",

        [theme.breakpoints.down("xs")]: {
            margin: "20px 0",
        },
    },
    accordionExpanded: {},
    concept: {
        width: "100%",
        justifyContent: "flex-start",
        paddingTop: 2.5,
        paddingLeft: 12,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 24,
        },
        alignItems: "flex-start",
        textAlign: "left",
        flexDirection: "column",
    },
    studyUnitSummary: {
        padding: 0,
        height: 81,
        alignItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            height: 60,
        },
    },
    studyUnitSummaryRemainder: {
        padding: 0,
        height: 111,
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            height: 80,
        },
    },
    studyUnitSummaryRemainerExpanded: {
        marginTop: 30,
        [theme.breakpoints.down("xs")]: {
            marginTop: 16,
        },
    },
    studyUnitSummaryContent: {
        margin: 0,
        height: 50,
        [theme.breakpoints.down("xs")]: {
            height: 36,
        },
    },
    dividerStudyunit: {
        backgroundColor: "#E1E1E1",
    },
    studyUnitDetails: {
        padding: "8px 0 0 45px",
        width: "fit-content",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
        },
    },
    studyUnitTimeline: {
        marginBottom: 0,
        paddingBottom: 0,
        [theme.breakpoints.down("xs")]: {
            padding: "6px 6px",
        },
    },
    levelIndicator: {
        position: "relative",
        top: -42,
        left: 90,
        [theme.breakpoints.down("xs")]: {
            top: -44,
            left: 82,
        },
    },
    testLevelIndicator: {
        [theme.breakpoints.down("xs")]: {
            left: 65,
        },
    },
    conceptListBox: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            marginBottom: 20,
        },
    },
    timelineConnector: {
        margin: "4px 0",
        borderLeft: "4px dotted #E1E1E1",
        width: 0,
        backgroundColor: "transparent",
    },
    dot: {
        backgroundColor: "#ffffff",
        boxShadow: "none",
        padding: 0,
        margin: 0,
        width: 32,
        height: 32,
        [theme.breakpoints.down("xs")]: {
            width: 24,
            height: 24,
            marginTop: 2,
        },
    },
    studyUnitExpandIcon: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    circularProgress: {
        display: "inherit",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    videoWrapper: {
        height: 50,
        width: 50,
        borderRadius: "100%",
        backgroundColor: "#00A71B",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    videoIcon: {
        color: "#ffffff",
        fontSize: 30,
    },
    video: {
        display: "none",
    },
    conceptLists: {
        marginTop: 40,
        marginBottom: 120,
    },
    expandMoreIcon: {
        color: "#AFB0B8",
    },
}))


const StyledAccordion = withStyles(() => ({
    root: {
        "&$expanded": {
            margin: 0,
        },
    },
    expanded: {},
}))(Accordion);

const StyledAccordionSummary = withStyles(() => ({
    root: {
        "&$expanded": {
            minHeight: 50,
            height: 50,
        },
    },
    content: {
        "&$expanded": {
            margin: 0,
        },
    },
    expanded: {},
    expandIcon: {},
}))(AccordionSummary);

function ConceptList({ concept, order, handleProblemToggle, handleStudyUnitToggle, handleConceptToggle, isDisabled, checkedProblems }) {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(
        new Array(100)
            .fill(1)
            .reduce((acc, curr, i) => ({...acc, [i]: false}), {})
    );

    const studyUnitSummaryDescription = (studyUnit) => {
        const isIntroCnt = studyUnit.introProblemCount;
        const isTestCnt = studyUnit.testProblemCount;
        const quizCnt = studyUnit.totalProblemCount - isIntroCnt - isTestCnt;

        const isIntroStr = isIntroCnt ? `Lesson ${isIntroCnt}개, ` : "";
        const isTestStr = isTestCnt ? `Test ${isTestCnt}개, ` : "";
        const quizStr = quizCnt ? `Quiz ${quizCnt}개, ` : "";

        const retStr = isIntroStr + quizStr + isTestStr;

        return retStr && retStr.slice(0, -2);
    };

    const handleExpand = (id) => {
        setExpanded((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handleStudyUnitChange = (conceptId, studyUnitId, id) => () => {
        if (!expanded) return;

        handleExpand(id);
    };

    const getStudyUnitChecked = (studyUnit, checkedProblems) => {
        return studyUnit.problems.every(problem => checkedProblems.has(problem.id));
    }

    const getStudyUnitIndeterminate = (studyUnit, checkedProblems) => {
        if(getStudyUnitChecked(studyUnit, checkedProblems)) return false;
        return studyUnit.problems.some(problem => checkedProblems.has(problem.id));
    }

    const getConceptChecked = (concept, checkedProblems) => {
        return concept.studyUnits.every(studyUnit => studyUnit.problems.every(problem => checkedProblems.has(problem.id)));
    }

    const getConceptIndeterminate = (concept, checkedProblems) => {
        if(getConceptChecked(concept, checkedProblems)) return false;
        return concept.studyUnits.some(studyUnit => studyUnit.problems.some(problem => checkedProblems.has(problem.id)));
    }

    return useObserver(() => {
        return (
            <StyledAccordion
                className={classes.accordionRoot}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    classes={{
                        content: classes.accordionContent,
                    }}
                    className={classes.accordionSummary}
                    expandIcon={
                        <ExpandMoreIcon className={classes.expandMoreIcon}/>
                    }
                >
                    <Checkbox
                        checked={getConceptChecked(concept, checkedProblems)}
                        indeterminate={getConceptIndeterminate(concept, checkedProblems)}
                        onClick={handleConceptToggle(concept, checkedProblems)}
                        disabled={isDisabled}
                    />
                    <Box className={classes.concept} >
                        <Typography
                            className={classes.conceptName}
                        >{`${order}. ${concept.name}`}</Typography>
                        <Typography className={classes.conceptDescription}>
                            {concept.description}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Box className={classes.conceptList}>
                        {concept.videoLink ? (
                            <StyledAccordion
                                expanded={false}
                                className={classes.studyUnit}
                            >
                                <StyledAccordionSummary
                                    className={classes.studyUnitSummary}
                                >
                                    <Box className={classes.videoWrapper}>
                                        <PlayArrowIcon
                                            className={classes.videoIcon}
                                        />
                                    </Box>
                                    <Box className={classes.studyUnitTypo}>
                                        <Typography
                                            className={classes.studyUnitTitle}
                                        >
                                            단원 설명
                                        </Typography>
                                        <Typography
                                            className={classes.studyUnitDescription}
                                        >
                                            {concept.videoLength}
                                        </Typography>
                                    </Box>
                                </StyledAccordionSummary>
                            </StyledAccordion>
                        ) : null}
                        {concept.studyUnits && concept.studyUnits.length
                            ? concept.studyUnits.map((studyUnit, i) => {
                                const k = concept.videoLink ? i + 1 : i;

                                return (
                                    <Box key={`${studyUnit.name}-${i}`}>
                                        {k !== 0 && (
                                            <Divider
                                                className={
                                                    classes.dividerStudyunit
                                                }
                                            />
                                        )}
                                        <StyledAccordion
                                            expanded={expanded[k]}
                                            className={classes.studyUnit}>
                                            <StyledAccordionSummary
                                                classes={{
                                                    content:
                                                    classes.studyUnitSummaryContent,
                                                }}
                                                className={clsx(
                                                    classes.studyUnitSummary,
                                                    k &&
                                                    classes.studyUnitSummaryRemainder,
                                                    k &&
                                                    expanded[k] &&
                                                    classes.studyUnitSummaryRemainerExpanded
                                                )}
                                                onClick={handleStudyUnitChange(
                                                    concept.id,
                                                    studyUnit.id,
                                                    k.toString()
                                                )}
                                                expandIcon={
                                                    <ExpandMoreIcon
                                                        className={
                                                            classes.studyUnitExpandIcon
                                                        }
                                                    />
                                                }
                                            >
                                                <Checkbox
                                                    checked={getStudyUnitChecked(studyUnit, checkedProblems)}
                                                    indeterminate={getStudyUnitIndeterminate(studyUnit, checkedProblems)}
                                                    onClick={handleStudyUnitToggle(studyUnit, checkedProblems)}
                                                    disabled={isDisabled}
                                                />
                                                <Box className={classes.concept} >
                                                    <Typography
                                                        className={
                                                            classes.studyUnitTitle
                                                        }
                                                    >
                                                        {studyUnit.name}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.studyUnitDescription
                                                        }
                                                    >
                                                        {expanded[k]
                                                            ? studyUnit.description
                                                            : studyUnitSummaryDescription(
                                                                studyUnit
                                                            )}
                                                    </Typography>
                                                </Box>
                                            </StyledAccordionSummary>
                                            <AccordionDetails
                                                className={
                                                    classes.studyUnitDetails
                                                }
                                            >
                                                <Timeline
                                                    align="left"
                                                    className={
                                                        classes.studyUnitTimeline
                                                    }
                                                >
                                                    {studyUnit.problems &&
                                                    studyUnit.problems.length
                                                        ? studyUnit.problems.map(
                                                            (problem, j) => (
                                                                <TimelineItem
                                                                    key={`timeline-${problem.name}-${j}`}
                                                                >
                                                                    <TimelineOppositeContent
                                                                        style={{
                                                                            display:
                                                                                "none",
                                                                        }}
                                                                    />
                                                                    <TimelineSeparator>
                                                                        <Checkbox
                                                                            checked={checkedProblems.has(problem.id)}
                                                                            onChange={()=>{
                                                                                handleProblemToggle(problem, checkedProblems)
                                                                            }}
                                                                            disabled={isDisabled}
                                                                        />
                                                                    </TimelineSeparator>
                                                                    <TimelineContent
                                                                        className={
                                                                            classes.timelineContent
                                                                        }
                                                                    >
                                                                        <Button
                                                                            className={
                                                                                classes.problem
                                                                            }
                                                                            onClick={()=>{
                                                                                handleProblemToggle(problem, checkedProblems)
                                                                            }}
                                                                            disabled={isDisabled}
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    classes.problemTag
                                                                                }
                                                                            >
                                                                                {problem.isIntro
                                                                                    ? "Lesson"
                                                                                    : problem.isTest
                                                                                        ? "Test"
                                                                                        : "Quiz"}
                                                                            </Typography>
                                                                            <Typography
                                                                                align="right"
                                                                                className={
                                                                                    classes.studyUnitText}
                                                                            >
                                                                                {problem.name}
                                                                            </Typography>
                                                                        </Button>
                                                                        <Box
                                                                            className={clsx(
                                                                                classes.levelIndicator,
                                                                                !problem.isIntro &&
                                                                                classes.testLevelIndicator
                                                                            )}
                                                                        >
                                                                            <LevelIndicator
                                                                                max={3}
                                                                                num={LevelType[
                                                                                    problem.level
                                                                                    ] + 1 }
                                                                                fontSize={8}
                                                                            />
                                                                        </Box>
                                                                    </TimelineContent>
                                                                </TimelineItem>
                                                            )
                                                        )
                                                        : null}
                                                </Timeline>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </Box>
                                );
                            })
                            : null}
                    </Box>
                </AccordionDetails>
            </StyledAccordion>
        );
    })
}


export function ConceptLists({ concepts, handleProblemToggle, handleStudyUnitToggle, handleConceptToggle, isDisabled, checkedProblems }) {
    const classes = useStyles();

    return (
        <Box className={classes.paper}>
            {concepts
                .filter((concept) => !concept.isHidden)
                .map((concept, i) => (
                    <Box
                        key={`Concept-${i}`}
                        className={classes.conceptListBox}
                    >
                        {i !== 0 && <Divider className={classes.divider} />}
                        <ConceptList
                            handleProblemToggle = {handleProblemToggle}
                            handleStudyUnitToggle = {handleStudyUnitToggle}
                            handleConceptToggle = {handleConceptToggle}
                            isDisabled = {isDisabled}
                            checkedProblems={checkedProblems}
                            concept={concept}
                            order={i + 1}
                        />
                    </Box>
                ))}
        </Box>
    );
}


export const ProblemForm = ({ missionId, handleChange, homeworkProblems, isDisabled, checkedProblems, setCheckedProblems }) => {
    const classes = useStyles();

    const { missionStore } = useStores();
    const [loading, setLoading] = useState(true);

    const addProblem = problem => {
        setCheckedProblems(prev => new Set([...prev, problem]))
    }

    const removeProblem = problem => {
        setCheckedProblems(prev => new Set([...prev].filter(x => x !== problem)))
    }

    useEffect(() => {
        const disposer = autorun(() => {
            setLoading(true);
            const { selectedMission } = missionStore;
            missionStore.fetchMission({missionOrder: selectedMission.order, missionId: selectedMission.id}).then(()=>{
                setLoading(false);
            })
        });

        return () => {
            disposer();
        }
    }, []);

    const updateSortedProblemOrder = (problems) => {
        problems.sort(function(a, b) {
            if(a.missionOrder !== b.missionOrder) return  a.missionOrder - b.missionOrder;
            if(a.conceptOrder !== b.conceptOrder) return a.conceptOrder - b.conceptOrder;
            if(a.studyUnitOrder !== b.studyUnitOrder) return a.studyUnitOrder - b.studyUnitOrder;
            return a.problemOrder - b.problemOrder;
        })
        return problems.map((problem, i) => ({...problem, order: i+1}));
    }

    const updateProblems = (missionId, hwProblems, problem, checkedProblems) => {
        if(checkedProblems.has(problem.id)) {
            const deletedProblems = hwProblems.filter(myProblem => myProblem.id !== problem.id);
            removeProblem(problem.id)
            return updateSortedProblemOrder(deletedProblems);
        }
        else {
            const addedProblems = hwProblems.concat(new ProblemModel({...problem, score:100, missionId:missionId}));
            addProblem(problem.id)
            return updateSortedProblemOrder(addedProblems);
        }
    }

    const handleProblemToggle = (missionId, hwProblems) => (problem, checkedProblems) => {
        const newProblems = updateProblems(missionId, hwProblems, problem, checkedProblems);

        handleChange({target: {
            name: 'problems',
            value: newProblems
        }});
    }

    const handleStudyUnitToggle = (missionId, hwProblems) => (studyUnit, checkedProblems) => (e) => {
        if(!studyUnit || !studyUnit.problems || !studyUnit.problems.length)
            return null;

        const { checked } = e.target;
        studyUnit.problems.forEach((problem) => {
            if(checkedProblems.has(problem.id) !== checked) {
                hwProblems = updateProblems(missionId, hwProblems, problem, checkedProblems);
            }
        })

        // avoid triggering the document click handler to dismiss the clock
        e.stopPropagation();

        handleChange({target: {
            name: 'problems',
            value: hwProblems
        }});
    }

    const handleConceptToggle = (missionId, hwProblems) => (concept, checkedProblems) => (e) => {
        if(!concept || !concept.studyUnits || !concept.studyUnits.length)
            return null;

        // avoid triggering the document click handler to dismiss the clock
        e.stopPropagation();

        const { checked } = e.target;
        concept.studyUnits.forEach((studyUnit) => {
            if(studyUnit && studyUnit.problems && studyUnit.problems.length)
                studyUnit.problems.forEach((problem) => {
                    if(checkedProblems.has(problem.id) !== checked) {
                        hwProblems = updateProblems(missionId, hwProblems, problem, checkedProblems);
                    }
                })
        })

        handleChange({target: {
                name: 'problems',
                value: hwProblems
            }});
    }

    return useObserver(() => {
        const { concepts } = missionStore;

        return (
            <LoadingOverlay
                active={loading}
                spinner
                text="문제집을 불러오는 중입니다."
                className={classes.loadingOverlay}
            >
                {concepts.length && concepts[0] ? (
                    <Box className={classes.rootMission}>
                        <Box className={classes.conceptLists}>
                            <ConceptLists
                                handleProblemToggle = {handleProblemToggle(missionId, homeworkProblems)}
                                handleStudyUnitToggle = {handleStudyUnitToggle(missionId, homeworkProblems)}
                                handleConceptToggle = {handleConceptToggle(missionId, homeworkProblems)}
                                isDisabled={isDisabled}
                                concepts={concepts}
                                checkedProblems={checkedProblems}
                            />
                        </Box>
                    </Box>
                ) : null}
            </LoadingOverlay>
        );
    });
};
