export const baseURL = `${process.env.REACT_APP_WS_HOST}/ws/v1`;

export const WebSocketType = {
    Authentication: "authentication",
    AdminAuthentication: "admin_authentication",
    ConnectionEstablish: "connection_establish",
    SubscribeSubmission: "subscribe_submission",
    SubscribeSuccess: "subscribe_success",
    SubscribeFailed: "subscribe_failed",
    PublishSubmission: "publish_submission",
    UpdataSubmission: "update_submission",
}
