import { createTheme } from '@material-ui/core';

export const sidebarTheme = createTheme({
    palette: {
        default: {
            main: '#000000'
        },
        primary: {
            main: '#f3b042',
            dark: '#e99306',
            contrastText: "#ffffff"
        },
        secondary: {
            extraLight : "#cfd8dc",
            light: '#88BEDA',
            main: '#456795',
            dark : '#252525'
        },
        background: {
            light: '#fff',
            heavyLight: '#f8f8f8',
            main: '#fbfbfc',
            heavy: '#f7f7f7',
            dark: '#000000',
        },
        text: {
            extraLight: '#fff',
            light: '#b1b1b5',
            primary: '#212121',
            secondary: '#2d3439'
        }
    },
    typography: {
        fontFamily: 'Spoqa Han Sans Neo, Roboto, Oxygen',
        title: {
            fontWeight: 700,
            fontSize: 40
        },
        h1: {
            fontWeight: 700,
            fontSize: 28
        },
        h2: {
            fontWeight: 700,
            fontSize: 24
        },
        h3: {
            fontWeight: 500,
            fontSize: 20
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 18
        },
        body1: {
            fontWeight: 400,
            fontSize: 16
        },
        caption: {
            fontWeight: 500,
            fontSize: 14
        },
        caption2: {
            fontWeight: 400,
            fontSize: 12
        }
    },
});
