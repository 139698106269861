import React, {createRef, useState} from "react";
import { useObserver } from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import {FormControlLabel, makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {SimpleEditTable} from "../../../../../common/components/SimpleTable";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from 'moment'
import {ProblemForm} from "./ProblemForm"
import {MissionSelectForm} from "./MissionSelectForm";
import {useStores} from "../../../../../common/hooks/UseStores";
import {ProblemOldEdit} from "./ProblemOldEditForm";
import Switch from "@material-ui/core/Switch";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '20px 0'
    },
    button: {
        margin: '0 10px'
    },
    buttonCancel: {
        margin: '0 10px',
        backgroundColor: 'pink',
        '&:hover': {
            backgroundColor: '#ffb2c6'
        }
    },
    switch: {
        marginRight: '5px',
    },
}))


export const HomeworkEdit = ({homework, handleCancel, handleUpdate, oldVersion, checkedProblems, handleToggleVersion}) => {
    const classes = useStyles();

    const submitRef = createRef();

    const { missionStore } = useStores();
    const [inputs, setInputs] = useState({
        ...homework,
        startedAt: moment(homework.startedAt, 'YY/MM/DD hh:mm a'),
        finishedAt: moment(homework.finishedAt, 'YY/MM/DD hh:mm a'),
    });
    const [checkedProblemsSet, setCheckedProblemsSet] = useState(new Set(checkedProblems))

    const handleChange = (e) => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        })
    }

    const filterItems = (item) => {
        if (item === 'id' || item === 'problems')
            return false;
        return true;
    }

    const overrideItems = (curr) => {
        if(curr === 'startedAt' || curr === 'finishedAt') {
            return ({
                key: curr,
                value: <KeyboardDateTimePicker
                    value={inputs[curr]}
                    onChange={(date) => handleChange({target: {name: curr, value: date}})}
                    format='YY/MM/DD kk:mm'
                />
            })
        }

        return ({
            key: curr,
            value: homework[curr]
        })
    }

    const items = Object.keys(homework).filter(filterItems).map(overrideItems);

    return useObserver(() => {
        const { selectedMission } = missionStore;

        return (
            <Paper className={classes.root}>
                <Box>
                    <SimpleEditTable items={items} handleChange={handleChange} inputs={inputs} submitRef={submitRef}/>
                </Box>
                <FormControlLabel
                    className={classes.switch}
                    control={<Switch color="primary" checked={!oldVersion} onChange={handleToggleVersion} />}
                    label="문제집에서 미션 고르기"
                    labelPlacement="start"
                />
                {!oldVersion ?
                    <Box>
                        <MissionSelectForm homework={inputs}/>
                        <ProblemForm missionId={selectedMission.id} handleChange={handleChange}
                                     homeworkProblems={inputs.problems} isDisabled={false}
                                     checkedProblems={checkedProblemsSet} setCheckedProblems={setCheckedProblemsSet}/>
                    </Box>
                    : <ProblemOldEdit
                        homeworkProblems={inputs.problems}
                        handleChange={handleChange}
                    />
                }
                <Box className={classes.buttons}>
                    <Button variant='contained' onClick={() => handleUpdate(inputs)} className={classes.button}>
                        SUBMIT
                    </Button>
                    <Button onClick={handleCancel} variant='contained' className={classes.buttonCancel}>
                        CANCEL
                    </Button>
                </Box>
            </Paper>
        )});
}
