import React from "react";
import {useObserver} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import TablePagination from "@material-ui/core/TablePagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import {CodeDialogTemplate} from "./CodeDialogTemplate";
import TableBody from "@material-ui/core/TableBody";
import {useState} from "react";
import {SubmissionStatusToColor} from "../modules/model/SubmissionModel";
import Moment from "react-moment";
import {SubmissionStatusType} from "../modules/model/SubmissionModel";
import {LoadingOverlay} from "../../../../../common/components/LoadingOverlayMUI";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        minHeight: "80vh",
        padding: theme.spacing(10)
    },
    table: {
    },
    tableRow: {
        cursor: "pointer"
    },
}));

const getProcessStr = (submission) => {
    if(submission.result.status !== SubmissionStatusType.InProgress)
        return '';

    return `(${submission.result.process}%)`
}

export const SubmissionTemplate = ({submissions, getSubmissions, count, page, rowsPerPage, isFetching}) => {
    const classes = useStyles();

    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(true);

    const handleClose = () => {
        setDialogOpen(false);
    }

    const handleClick = (submission) => {
        setSelectedSubmission(submission);
        setDialogOpen(true);
    }

    return useObserver(() => {
        if(!submissions)
            return null;

        return (
            <Box className={classes.root}>
                <Box>
                    <LoadingOverlay
                        active={isFetching}
                    >
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Username</StyledTableCell>
                                        <StyledTableCell align="center">Submitted</StyledTableCell>
                                        <StyledTableCell align="center">Problem</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="center">Runtime</StyledTableCell>
                                        <StyledTableCell align="center">Memory</StyledTableCell>
                                        <StyledTableCell align="center">Language</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {submissions.slice(0, rowsPerPage).map((submission) => (
                                        <TableRow key={submission.id} hover className={classes.tableRow}
                                                  onClick={() => handleClick(submission)}>
                                            <StyledTableCell align="center">
                                                {submission.username}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Moment format="YYYY년 MM월 DD일 HH:mm:ss">
                                                    {submission.createdAt}
                                                </Moment>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{submission.problemName}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Box component="span" color={SubmissionStatusToColor[submission.result.status]}>
                                                    {submission.result.status}{` ${getProcessStr(submission)}`}
                                                </Box>
                                            </StyledTableCell>
                                            {submission.result.runtime !== -1 ?
                                                <StyledTableCell
                                                    align="center">{submission.result.runtime}ms</StyledTableCell> :
                                                <StyledTableCell align="center">N/A</StyledTableCell>
                                            }
                                            {submission.result.memory !== -1 ?
                                                <StyledTableCell align="center">{submission.result.memory}MB</StyledTableCell> :
                                                <StyledTableCell align="center">N/A</StyledTableCell>
                                            }
                                            <StyledTableCell align="center">{submission.language}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={count}
                            rowsPerPageOptions={[rowsPerPage]}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={getSubmissions}
                        />
                    </LoadingOverlay>
                </Box>
                <CodeDialogTemplate submission={selectedSubmission} open={dialogOpen} handleClose={handleClose}/>
            </Box>
        )
    })
};
