import {computed, flow, makeAutoObservable} from 'mobx';
import {HomeworkModel} from "../model/HomeworkModel";
import HomeworkRepository from "../repository/HomeworkRepository";

export default class HomeworkStore {
    homeworks = [];
    homework = undefined;
    count = 0;
    bizClassId = 0;
    bizGroupId = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetch: flow,
            updateHomework: flow,
            deleteHomework: flow,
            clearHomework: flow,
            addHomework: flow,
            downloadExcel: flow,
            sendCertEmail: flow,
            testCertEmail: flow,
            countCertificates: flow,
            checkedProblems: computed,
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId, bizClassId, page, pageSize}) {
        this.bizClassId = bizClassId;
        this.bizGroupId = bizGroupId;
        const response = yield HomeworkRepository.findAll({bizGroupId, bizClassId, page, pageSize});
        const newHomeworks = response.data.results.map(homework => new HomeworkModel(homework));
        this.homeworks.replace(newHomeworks);
        this.count = response.data.count;
        return newHomeworks;
    }

    *fetch({bizGroupId, bizClassId, homeworkId}) {
        const response = yield HomeworkRepository.find({bizGroupId, bizClassId, homeworkId});
        this.homework = new HomeworkModel(response.data);
        return this.homework;
    }

    *clearHomework() {
        this.homework = undefined;
    }

    *updateHomework({bizGroupId, bizClassId, homeworkId, body}) {
        const response = yield HomeworkRepository.updateHomework({bizGroupId, bizClassId,  homeworkId, body});
        const newHomework = new HomeworkModel(response.data);
        this.homeworks.replace(this.homeworks.map(homework => homeworkId === homework.id ? newHomework : homework));
        this.homework = newHomework;
    }

    *deleteHomework({bizGroupId, bizClassId, homeworkId}) {
        const response = yield HomeworkRepository.deleteHomework({bizGroupId, bizClassId,  homeworkId});
        this.homeworks.replace(this.homeworks.filter(homework => homeworkId !== homework.id));
        this.count--;
    }

    *addHomework({bizGroupId, bizClassId, body}) {
        const response = yield HomeworkRepository.addHomework({bizGroupId, bizClassId,  body});
        this.homeworks.replace([...this.homeworks, new HomeworkModel(response.data)]);
        this.count++;
    }

    *downloadExcel({bizClassId, reportType, percentOfO, certificateMaxX}) {
        const response = yield HomeworkRepository.getExcel({bizClassId, reportType, percentOfO, certificateMaxX});
        return response;
    }

    *sendCertEmail({bizClassId, certificateMaxX, certNum}) {
        const response = yield HomeworkRepository.sendCertEmail({bizClassId, certificateMaxX, certNum});
        return response;
    }

    *testCertEmail({bizClassId}) {
        const response = yield HomeworkRepository.testCertEmail({bizClassId});
        return response;
    }

    *countCertificate({bizClassId, certificateMaxX}) {
        const response = yield HomeworkRepository.countCertificate({bizClassId, certificateMaxX});
        return response;
    }

    get checkedProblems() {
        const set = new Set();
        if(!this.homework)
            return set;

        this.homework.problems.forEach((problem) => {
            set.add(problem.id);
        })

        return set;
    }
}
