import {makeAutoObservable, set} from "mobx";
import moment from "moment";
import {pickCls} from "../../../../utils/immutableLodash";
import {renameKeys} from "../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../utils/camelize";

const bizClassConvertMap = {

};

export class BizClassModel {
    id = 0;
    name = '';
    maxUsersCnt = 0;
    startedAt = '';
    finishedAt = '';

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const bizClass = pickCls(this, renameKeys(camelizeKeys(data), bizClassConvertMap));
        bizClass.startedAt = moment(bizClass.startedAt, moment.ISO_8601).format('YY/MM/DD')
        bizClass.finishedAt = moment(bizClass.finishedAt, moment.ISO_8601).format('YY/MM/DD')
        set(this, bizClass);
    }
}

