import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    button: {
        fontSize: theme.typography.h3.fontSize
    }
}))

export const HomeworkMenu = ({homeworkId, handleChange, homeworks}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    }

    const handleMenuClick = id => {
        handleChange(id);
        handleClose();
    }

    return (
        <Box className={classes.root}>
            <Button className={classes.button} onClick={handleClick} variant='contained'>
                {homeworks.length && homeworks.find(homework => homework.id === Number(homeworkId)).name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    homeworks.length && homeworks.map(homework => (
                        <MenuItem onClick={()=>handleMenuClick(homework.id)}>
                            {homework.name}
                        </MenuItem>
                    ))
                }
            </Menu>
        </Box>
    )
}
