import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import ChatIcon from '@material-ui/icons/Chat';
import ReplyIcon from '@material-ui/icons/Reply';
import CreateIcon from '@material-ui/icons/Create';
import Markdown from "../../../../../../common/components/Markdown";
import {CommentEditor} from "./CommentEditor";
import {useStores} from "../../../../../../common/hooks/UseStores";
import {ReplyContainer} from "../reply/ReplyContainer";
import {CommentDAOModel} from "../../../../../../common/modules/comment/model/CommentModel";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(1)
    },
    commentMeta: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.text.light
    },
    commentMetaUser: {
        display: "flex"
    },
    commentMetaItem: {
        marginLeft: theme.spacing(1)
    },
    commentMain: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1)
    },
    commentFooter: {
        display: "flex",
        marginTop: theme.spacing(1),
        color: theme.palette.secondary.main
    },
    commentFooterItem: {
        display: "flex",
        marginLeft: theme.spacing(1),
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.secondary.light
        }
    },
    commentFooterItemText: {
        marginTop: 4,
        marginLeft: 3
    },
    replyWrapper: {

    }
}))

const _CommentViewForm = ({content}) => {
    return (
        <Box>
            <Markdown source={content}/>
        </Box>
    )
};

const _CommentEditForm = ({content, onSubmit, onChange, onCancel}) => {
    return (
        <CommentEditor content={content} buttonText="Update" onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
    )
};

export const Comment = ({questionId, comment}) => {
    const classes = useStyles();
    const { authStore, commentStore } = useStores();
    const [isEditing, setIsEditing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(comment.content);
    const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen(prev => !prev);
    }

    const onCancel = () => {
        setContent(comment.content);
        setIsEditing(false);
    }

    const onChange = (e) => {
        setContent(e.target.value);
    }

    const onSubmit = () => {
        const newComment = new CommentDAOModel({content});
        commentStore.updateComment({questionId, commentId: comment.id, body: newComment});
        setIsEditing(false);
    }

    const handleIsEditing = () => {
        setIsEditing(prev => !prev)
    }

    const handleIsReplyFormOpen = () => {
        setIsReplyFormOpen(prev => !prev)
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.commentMeta}>
                <Box className={classes.commentMetaUser}>
                    <SvgIcon>
                        <path d="M9 8c1.66 0 2.99-1.34 2.99-3S10.66 2 9 2C7.34 2 6 3.34 6 5s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V16h14v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                    </SvgIcon>
                    <Typography variant="caption"> {comment.username} </Typography>
                </Box>
                <Typography variant="caption" className={classes.commentMetaItem}>
                    <Moment format="YYYY년 MM월 DD일 HH:mm:ss" parse={"YY/MM/DD hh:mm:ss a"}>
                        {comment.createdAt}
                    </Moment>
                </Typography>
            </Box>
            <Box className={classes.commentMain}>
                {isEditing ?
                    <_CommentEditForm content={content} onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} /> :
                    <_CommentViewForm content={content} />
                }
                <Box className={classes.commentFooter}>
                    {comment.replyNum !== 0 ?
                        <Box className={classes.commentFooterItem} onClick={handleIsOpen}>
                            <ChatIcon />
                            <Typography className={classes.commentFooterItemText}
                                        variant="caption">
                                {isOpen ? "Hide" : "Show"} {comment.replyNum} replies
                            </Typography>
                        </Box> : null
                    }
                    <Box className={classes.commentFooterItem} onClick={handleIsReplyFormOpen}>
                        <ReplyIcon />
                        <Typography className={classes.commentFooterItemText} variant="caption"> Reply </Typography>
                    </Box>
                    {authStore.isUserLoggedIn() && authStore.currUser.username === comment.username ? (
                        <Box className={classes.commentFooterItem} onClick={handleIsEditing}>
                            <CreateIcon/>
                            <Typography className={classes.commentFooterItemText}
                                        variant="caption"> Edit </Typography>
                        </Box>
                    ) : null
                    }
                </Box>
                <Box className={classes.replyWrapper}>
                    <ReplyContainer comment={comment} questionId={questionId}
                                    isOpen={isOpen} isReplyFormOpen={isReplyFormOpen}
                                    handleIsReplyFormOpen={handleIsReplyFormOpen}/>
                </Box>
            </Box>
        </Box>
    )
}
