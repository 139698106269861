import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {Checkbox, makeStyles, TextField, Typography,} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AlertDialog from "../../../../../common/components/dialog/AlertDialog";
import AuthStore from "../../../../../common/modules/auth/modules/store/AuthStore";
import {useStores} from "../../../../../common/hooks/UseStores";
import {errorToast} from "../../../../../utils/errorHandler";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '20px 0'
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0'
    },
    checklabel: {
        verticalAlign: 'middle',
        lineHeight: '50px',
        height: '50px',
    },
    texts: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '20px 0'
    },
    button: {
        margin: '0 10px'
    },
    buttonCancel: {
        margin: '0 10px',
        backgroundColor: 'pink',
        '&:hover': {
            backgroundColor: '#ffb2c6'
        }
    },
    textField: {
        fontSize: '20',
        color: 'red',
    },
}))


export const ExcelEdit = ({excelCancel, downloadExcel, sendCertEmail, testCertEmail, emailEditing, emailEdit, emailCancel, countCertificate}) => {
    const classes = useStyles();

    const [info, setInfo] = useState({
        percentOfO: '100',
        certificateMaxX: '2',
        certNum: '0'
    });

    const {percentOfO, certificateMaxX, certNum} = info;
    const {authStore} = useStores();
    const userId = authStore.currUser.id;

    const onChange = (e) => {
        const {value, name} = e.target;
        setInfo({
            ...info,
            [name]: value
        });
    };

    return (
        <Paper className={classes.root}>
            <Box className={classes.texts}>
                <TextField
                    className={classes.textField}
                    name="percentOfO"
                    label="O로 인정되는 문제 비율(%)"
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    value={percentOfO}
                />
            </Box>
            <Box className={classes.texts}>
                <TextField
                    className={classes.textField}
                    name="certificateMaxX"
                    label="수료 인정되는 최대 X"
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    value={certificateMaxX}
                />
            </Box>
            <Box className={classes.texts}>
                <TextField
                    className={classes.textField}
                    name="certNum"
                    label="수료 인원 체크"
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    value={certNum}
                />
            </Box>
            <Box className={classes.buttons}>
                <Button variant='contained' onClick={() => downloadExcel({reportType: 'summary', percentOfO, certificateMaxX})} className={classes.button}>
                    SUMMARY
                </Button>
                <Button variant='contained' onClick={() => downloadExcel({reportType: 'detail', percentOfO, certificateMaxX})} className={classes.button}>
                    DETAIL
                </Button>
            </Box>
            {(userId === 1) && <Box className={classes.buttons}>
                <Button variant='contained' onClick={() => emailEdit()} className={classes.button}>
                    수료증 보내기
                </Button>
                <Button variant='contained' onClick={testCertEmail} className={classes.button}>
                    수료증 테스트하기
                </Button>
                <Button variant='contained' onClick={() => countCertificate({certificateMaxX}).then(
                    (response) => {
                        setInfo({
                            ...info,
                            certNum: response.data[0]
                        })
                    }
                ).catch(
                    (error) => {
                        errorToast(error);
                    }
                )} className={classes.button}>
                    수료인원 확인하기
                </Button>
            </Box>}
            <Box className={classes.buttons}>
                <Button onClick={excelCancel} variant='contained' className={classes.buttonCancel}>
                    CANCEL
                </Button>
            </Box>
            <AlertDialog
                title={'정말로 수료증 메일을 보내시겠습니까?'}
                description={'수료 자격이 있는 학생들에게 메일이 보내집니다.'}
                handleConfirm={()=>sendCertEmail({certificateMaxX, certNum})}
                handleClose={emailCancel}
                open={emailEditing}
            />
        </Paper>
    )
}
