import {flow, makeAutoObservable} from 'mobx';
import {BizGroupModel} from "../model/BizGroupModel";
import BizGroupRepository from "../repository/BizGroupRepository";

export default class BizGroupStore {
    bizGroups = [];
    bizGroup = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            fetch: flow,
        }, {autoBind: true});
    }

    *fetchAll() {
        const response = yield BizGroupRepository.findAll();
        this.bizGroups.replace(response.data.map(bizGroup => new BizGroupModel(bizGroup)));
    }

    *fetch({bizGroupId}) {
        const response = yield BizGroupRepository.find({bizGroupId});
        this.bizGroup = new BizGroupModel(response.data);
    }
}
