import Button from "@material-ui/core/Button";
import React, {createRef, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import CodeIcon from "@material-ui/icons/Code";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {makeStyles} from "@material-ui/styles";
import insertTextAtCursor from "insert-text-at-cursor";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Markdown from "../../../../../../common/components/Markdown";

const useStyles = makeStyles( theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2)
    },
    textField: {
        display: "flex",
        backgroundColor: theme.palette.background.light
    },
    buffer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: theme.spacing(1),
        paddingRight: 0
    },
    appbarBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2)
    },
    tab: {
        textTransform: 'none',
        minWidth: 72,
    }
}));

export const CommentEditor = ({content, buttonText, onChange, onSubmit, onCancel}) => {
    const classes = useStyles();

    const write = "write";
    const preview = "preview"
    const [isPreview, setIsPreview] = useState(false);

    const scriptRef = createRef();

    useEffect(() => {
        if(!scriptRef.current)
            return;

        scriptRef.current.focus();
        scriptRef.current.selectionStart = content.length;
        scriptRef.current.selectionEnd = content.length;
    }, [scriptRef.current])

    const handleTab = (event, newValue) => {
        if(newValue === preview) {
            setIsPreview(true);
        } else {
            setIsPreview(false);
            scriptRef.current.focus();
            scriptRef.current.selectionStart = content.length;
            scriptRef.current.selectionEnd = content.length;
        }
    };

    const putPredefinedVar = (preStr) => {
        insertTextAtCursor(scriptRef.current, preStr);
    };

    return (
        <Paper className={classes.root}>
            <Box>
                <AppBar position="static" color="inherit" elevation={0}>
                    <Box className={classes.appbarBox}>
                        <Box>
                            <Tabs
                                value={isPreview ? preview : write}
                                onChange={handleTab}
                                indicatorColor="primary"
                                textColor="primary"
                                className={classes.tabs}
                            >
                                <Tab key={write} value={write} label="Write" className={classes.tab} />
                                <Tab key={preview} value={preview} label="Preview" className={classes.tab} />
                            </Tabs>
                        </Box>
                        <Box>
                            <IconButton onClick={() => putPredefinedVar('\n~~~\n여기에 코드를 넣어주세요\n~~~\n')} >
                                <CodeIcon fontSize='small'/>
                            </IconButton>
                            <IconButton onClick={() => putPredefinedVar('\n+ 여기에 항목을 적어주세요')} >
                                <FormatListBulletedIcon fontSize='small'/>
                            </IconButton>
                        </Box>
                    </Box>
                </AppBar>
            </Box>
            <ValidatorForm
                onSubmit={onSubmit}
                onError={errors => console.log(errors)}
            >
                {isPreview ?
                    <Markdown source={content}/> :
                    <TextValidator
                        placeholder="댓글을 작성해주세요. (마크다운 양식을 지원합니다)"
                        multiline
                        rowsmin={5}
                        maxRows={20}
                        value={content}
                        validators={['required']}
                        errorMessages={['필수 정보입니다.']}
                        onChange={onChange}
                        className={classes.textField}
                        inputRef={scriptRef}
                    />
                }
                <Box className={classes.buffer}>
                    {onCancel ?
                        <Button size="small" variant="contained" color="default" onClick={onCancel}>
                            Cancel
                        </Button> :
                        null
                    }
                    <Button type="submit" size="small" variant="contained" color="secondary">
                        {buttonText}
                    </Button>
                </Box>
            </ValidatorForm>
        </Paper>
    )
};
