import {blobGET, DELETE, GET, PATCH, POST} from "../../../../../../../utils/api";

class HomeworkRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, page, pageSize}) {
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/?page=${page+1}&page_size=${pageSize || 10}`);
    }

    find({bizGroupId, bizClassId, homeworkId}) {
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/`);
    }

    updateHomework({bizGroupId, bizClassId, homeworkId, body}) {
        return PATCH(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/`, body);
    }

    deleteHomework({bizGroupId, bizClassId, homeworkId}) {
        return DELETE(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/`);
    }

    addHomework({bizGroupId, bizClassId, body}) {
        return POST(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/`, body);
    }

    getExcel({bizClassId, reportType, percentOfO, certificateMaxX}) {
        return GET(`/download-hw-excel/?biz_class_id=${bizClassId}&report_type=${reportType}&percent_of_o=${percentOfO}&certificate_max_x=${certificateMaxX}`);
    }

    sendCertEmail({bizClassId, certificateMaxX, certNum}) {
        return GET(`/send-certificate/?biz_class_id=${bizClassId}&certificate_max_x=${certificateMaxX}&cert_num=${certNum}`);
    }

    testCertEmail({bizClassId}) {
        return blobGET(`/test-certificate/?biz_class_id=${bizClassId}`);
    }

    countCertificate({bizClassId, certificateMaxX}) {
        return GET(`/count-certificate/?biz_class_id=${bizClassId}&certificate_max_x=${certificateMaxX}`);
    }
}

// 싱글톤으로 리턴
export default new HomeworkRepository();
