import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CommentIcon from '@material-ui/icons/Comment';
import {useObserver} from "mobx-react-lite";
import {CommentReplyList} from "./CommentReplyList";
import {useStores} from "../../../../../../common/hooks/UseStores";
import {CommentEditor} from "./CommentEditor";
import {CommentDAOModel} from "../../../../../../common/modules/comment/model/CommentModel";
import {snackActions} from "../../../../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../../../../utils/errorHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3)
    },
    titleBox: {
        alignItems: "center",
        background: "#fafafa",
        borderStyle: "solid",
        borderColor: "#eeeeee",
        borderWidth: "1px 0",
        display: "flex",
        height: theme.spacing(6),
        padding: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(1)
    },
    content: {
        padding: theme.spacing(3),
    },
    commentForm: {
        marginTop: theme.spacing(1)
    },
    commentList: {
        marginTop: theme.spacing(1),
        overflowY: "auto"
    }
}));

export const CommentContainer = ({questionId, comments}) => {
    const classes = useStyles();
    const {commentStore, authStore} = useStores();
    const [content, setContent] = useState("");

    const onChange = (e) => {
        setContent(e.target.value);
    }

    const onSubmit = () => {
        const newComment = new CommentDAOModel({content, author: authStore.loginUser.id});
        commentStore.addComment({questionId, body: newComment}).then(
            () => {
                snackActions.success("정상적으로 제출되었습니다.");
                setContent("");
            }
        ).catch(
            (error) => {
                errorToast(error);
            }
        );
    }

    return (
    <Box className={classes.root}>
        <Box className={classes.titleBox}>
            <CommentIcon/>
            <Typography className={classes.title}>Comments</Typography>
        </Box>
        <Box className={classes.content}>
            <Box className={classes.commentForm}>
                <CommentEditor content={content} buttonText="Submit" onChange={onChange} onSubmit={onSubmit} />
            </Box>
            <Box className={classes.commentList}>
                <CommentReplyList questionId={questionId} comments={[...comments]}/>
            </Box>
        </Box>
    </Box>
    );
}
