import axios from 'axios';
import {getRefreshToken, getToken, removeRefreshToken, removeToken, removeTokenFromCookie, setToken} from "./cookies";
import {snackActions} from "./SnackbarUtilsConfigurator";

let isTokenRefreshing = false;
let refreshSubscribers = [];

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api/v1/biz`;

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const clearRefreshSubscribers = () => {
    refreshSubscribers = [];
}

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

axios.interceptors.response.use(response => {
    return response;
}, async error => {
    if(!error.response) {
        snackActions.error('서버 접속이 원활하지 않습니다.');
        return Promise.reject(error);
    }

    if (error.response.status === 401
        && error.config.url !== "api-token-refresh/"
    ) {
        const originalRequest = error.config;

        let access;

        const refresh = getRefreshToken();

        if (!isTokenRefreshing && refresh) {
            isTokenRefreshing = true;

            await unauthorizedPOST(
                "api-token-refresh/",
                {
                    refresh,
                }
            )
                .then((response) => {
                    access = response.data?.access;
                    setToken(access);
                })
                .catch(e => {
                    removeRefreshToken();
                    removeToken();
                    alert(
                        "로그인한지 30일이 경과되어 자동으로 로그아웃 되었습니다."
                    );
                    window.location.href = "/";
                })
                .finally(() => {
                    isTokenRefreshing = false;
                });
        }

        const retryOriginalRequest = refresh ? new Promise((resolve) => {
            addRefreshSubscriber((accessToken) => {
                originalRequest.headers.Authorization = "JWT " + accessToken;
                resolve(axios(originalRequest));
            })
        }) : await Promise.reject(error);

        if (!isTokenRefreshing) {
            if(access) onTokenRefreshed(access);
            clearRefreshSubscribers();
        }

        return retryOriginalRequest;
    }
    
    if (error.response.status === 404) {
        window.location.replace("/not-found");
    }

    return Promise.reject(error);
});

const config = () => {
    return {
        'headers': {
            "Content-Type": "application/json",
            Authorization: `JWT ${getToken()}`
        }
    }
};

const blob_config = () => {
    return {
        'headers': {
            "Content-Type": "application/json",
            Authorization: `JWT ${getToken()}`
        },
        'responseType': 'blob'
    }
};


const param_config = ( param ) => {
    return {
        'headers': {
            "Content-Type": "application/json",
            Authorization: `JWT ${getToken()}`
        },
        'params' : param
    }
};

const formdata_config = () => {
    return {
        'headers': {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${getToken()}`
        }
    }
};

const unauthorizedConfig = {
    headers: {
        "Content-Type": "application/json"
    },
};


export const GET = async (url) => await axios.get(url, config());
export const DELETE = async (url) => await axios.delete(url, config());
export const POST = async (url, data) => await axios.post(url, data, config());
export const PUT = async (url, data) => await axios.put(url, data, config());
export const PATCH = async (url, data) => await axios.patch(url, data, config());

export const blobGET = async (url) => await axios.get(url, blob_config());
export const unauthorizedGET = async (url) => await axios.get(url, unauthorizedConfig);
export const paramGET = async (url, param) => await axios.get(url, param_config(param));
export const unauthorizedPOST = async (url, data) => await axios.post(url, data, unauthorizedConfig);

export const postFormData = async(url, formData) => await axios.post(url, formData, formdata_config());
