import {makeAutoObservable, observable, set} from "mobx";
import {pickCls} from "../../../../utils/immutableLodash";
import {renameKeys} from "../../../../utils/renameKeys";
import {camelizeKeys} from "../../../../utils/camelize";
import {snakelizeKeys} from "../../../../utils/snakelize";
import moment from "moment";


const commentConvertMap = {

};

export class CommentModel {
    id = 0;
    content = '';
    username = '';
    createdAt = '';
    replyNum = 0;
    isAnonymous = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const comment = pickCls(this, renameKeys(camelizeKeys(data), commentConvertMap));
        comment.createdAt = moment(comment.createdAt, moment.ISO_8601).format('YY/MM/DD hh:mm:ss a')
        set(this, comment);
    }
}

const commentDAOConvertMap = {

};

export class CommentDAOModel {
    content = '';

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const comment = pickCls(this, renameKeys(snakelizeKeys(data), commentDAOConvertMap));
        set(this, comment);
    }
}
