import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import {List, ListItem, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    list: {
        background: "#FFFFFF",
        width: 300,
        marginTop: 20,
    },
    listItem: {
        borderRadius: "4px",
        fontSize: 16,
        lineHeight: "125%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        fontSize: 20,
        lineHeight: "120%",
        letterSpacing: "-0.6px",
        color: "#000000",
    },
}))

export const BizGroupsTemplate = ({bizGroups}) => {
    const classes = useStyles();

    const history = useHistory();

    const handleSelect = (bizGroupId) => {
        history.push(`/biz-groups/${bizGroupId}/biz-classes`);
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                접속할 그룹을 선택해주세요.
            </Typography>
            <List className={classes.list}>
                {bizGroups.map((bizGroup, i) => (
                    <ListItem
                        button
                        className={classes.listItem}
                        key={`bizGroup-${i}`}
                        onClick={() => handleSelect(bizGroup.id)}
                    >
                        {bizGroup.name}
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}