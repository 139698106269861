import {GET} from "../../../../../../../utils/api";
import {makeSortString} from "../../../../../../../utils/makeQueryString";

class TableDataRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, bizClassId, homeworkId, page, sortModel}) {
        const sortString = makeSortString(sortModel);
        return GET(`/biz-groups/${bizGroupId}/biz-classes/${bizClassId}/homeworks/${homeworkId}/table-data/?page=${page}${sortString}`);
    }
}

// 싱글톤으로 리턴
export default new TableDataRepository();
