import {computed, flow, makeAutoObservable} from 'mobx';
import {TableDataModel} from "../model/TableDataModel";
import TableDataRepository from "../repository/TableDataRepository";


export default class TableDataStore {
    tableDatas = [];
    count = 0;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            fetchAll: flow,
            usersSolveInfo: computed,
        }, {autoBind: true});
    }

    *fetchAll({bizGroupId, bizClassId, homeworkId, page, sortModel}) {
        const response = yield TableDataRepository.findAll({bizGroupId, bizClassId, homeworkId, page, sortModel});
        this.tableDatas.replace(response.data.results.map(tableData => new TableDataModel(tableData)));
        this.count = response.data.count;
    }

    get usersSolveInfo() {
        return this.tableDatas && this.tableDatas.length && this.tableDatas.map(tabledata => tabledata.solveInfo)
    }
}
