import React, {useEffect} from "react";
import {useStores} from "../../../../../common/hooks/UseStores";
import {useObserver} from "mobx-react-lite";
import {SubmissionTemplate} from "./SubmissionTemplate";

export const SubmissionContainer = ({bizGroupId, bizClassId}) => {

    const { submissionStore } = useStores();
    const {page, setPage} = submissionStore;

    const rowsPerPage = 13;

    useEffect(() => {
        submissionStore.fetchAll({bizGroupId, bizClassId, pageSize: rowsPerPage, page});
    }, []);

    useEffect(() => {
        submissionStore.open();

        return () => {
            submissionStore.close();
        }
    }, [page])

    const getSubmissions = (e, newPage) => {
        submissionStore.setIsFetching(true);
        submissionStore.fetchAll({bizGroupId, bizClassId, pageSize: rowsPerPage, page: newPage}).then(_ => {
            submissionStore.setIsFetching(false);
        });
        setPage(newPage);
    }

    return useObserver(() => {
        const { submissions, count, isFetching, page } = submissionStore;

        if(!submissions || !submissions.length)
            return null;

        return <SubmissionTemplate
            isFetching={isFetching}
            submissions={submissions}
            getSubmissions={getSubmissions}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
        />
    });
}
